import { Component, EventEmitter, inject, Input, Output, SimpleChanges } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { StructureInstance, StructureStatus, StructureUpdateModel } from "@models/structure.model";
import { GenericDialogComponent } from "../../shared/generic-dialog/generic-dialog.component";
import { DialogInvokingComponents } from "@models/generic-dialog.model";
import { StructureService } from "../../services/structure/structure.service";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Router } from "@angular/router";
import { SnackBarConfig } from "../../constants/snackbar.constants";

@Component({
    selector: "app-my-structures-list",
    templateUrl: "./my-structures-list.component.html",
    styleUrls: ["./my-structures-list.component.scss"],
})
export class MyStructuresListComponent {
    @Input() structuresData!: { count: number; rows: StructureInstance[] };
    @Input() showCheckboxes: boolean = false;
    @Output() loadMore = new EventEmitter<Boolean>();
    @Output() checkboxChange = new EventEmitter<number[]>();

    public userStructureData: StructureInstance[] = [];
    public showButton: boolean = false;
    private itemsPerPage = 10;
    private loadedItemsCount = 0;
    readonly dialog = inject(MatDialog);
    public selectedCheckboxes = 0;
    public structureStatus = StructureStatus;
    private scene!: THREE.Scene;

    constructor(
        private structureService: StructureService,
        private snackBar: MatSnackBar,
        private router: Router,
    ) {}

    ngOnChanges(changes: SimpleChanges): void {
        if (changes["structuresData"]) {
            this.setupPagination();
            this.setPage(1);
            const checkedIds = this.getCheckedItems().map((item) => {
                return item.id;
            });
            this.checkboxChange.emit(checkedIds);
        }
        this.showButton = this.structuresData.count > this.userStructureData.length;
    }

    private setupPagination(): void {
        this.setPage(1);
    }

    public setPage(page: number): void {
        const start = (page - 1) * this.itemsPerPage;
        const end = start + this.itemsPerPage;
        this.userStructureData = this.structuresData.rows;
        this.loadedItemsCount = end;
        this.updateShowButton();
    }

    public onViewMore(): void {
        this.loadMore.emit();
    }

    private updateShowButton(): void {
        this.showButton = this.loadedItemsCount < this.structuresData.count;
    }

    public onPublish(structureId: number, structureStatus: string): void {
        if (structureStatus !== StructureStatus.Saved) {
            return;
        }
        const dialogRef = this.dialog.open(GenericDialogComponent, {
            data: {
                componentName: DialogInvokingComponents.MyStructuresList,
                title: "Publish Structure",
                content:
                    "Are you sure you want to publish this structure? Once published, it will be visible to all users after admin approval.",
                firstBtn: "Publish",
                secondBtn: "Cancel",
            },
            autoFocus: false,
            restoreFocus: false,
        });

        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                this.structureService.publishStructures([structureId]).subscribe({
                    next: (response) => {
                        this.loadMore.emit(true);
                        this.snackBar.open(response.message, SnackBarConfig.Actions.CLOSE, {
                            duration: SnackBarConfig.Duration.MEDIUM,
                        });
                    },
                    error: (error) => {
                        this.snackBar.open(error, SnackBarConfig.Actions.CLOSE, {
                            duration: SnackBarConfig.Duration.MEDIUM,
                        });
                    },
                });
            }
        });
    }

    public onDelete(structureId: number, structureStatus: string): void {
        if (structureStatus !== StructureStatus.Saved) {
            return;
        }
        const dialogRef = this.dialog.open(GenericDialogComponent, {
            data: {
                componentName: DialogInvokingComponents.MyStructuresList,
                title: "Delete Structure",
                content: "Are you sure you want to permanently delete this structure? This action cannot be undone.",
                firstBtn: "Ok",
                secondBtn: "Cancel",
            },
            autoFocus: false,
            restoreFocus: false,
        });

        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                this.structureService.deleteStructures([structureId]).subscribe({
                    next: (response) => {
                        this.loadMore.emit(true);
                        this.snackBar.open(response.message, SnackBarConfig.Actions.CLOSE, {
                            duration: SnackBarConfig.Duration.MEDIUM,
                        });
                    },
                    error: (error) => {
                        console.log(error);
                        this.snackBar.open(error, SnackBarConfig.Actions.CLOSE, {
                            duration: SnackBarConfig.Duration.MEDIUM,
                        });
                    },
                });
            }
        });
    }

    public isDisabledPublishAndDelete(isStatus: string): boolean {
        return [StructureStatus.Published, StructureStatus.Approved, StructureStatus.Rejected].includes(
            isStatus as StructureStatus,
        );
    }

    //properties
    public openPropertiesDialog(userStructureData: StructureInstance): void {
        const dialogRef = this.dialog.open(GenericDialogComponent, {
            data: {
                componentName: DialogInvokingComponents.MyStructuresListProperties,
                title: "Structure properties",
                structureName: userStructureData.structureName.trim(),
                structureDescription: userStructureData.structureDescription.trim(),
                status: userStructureData.status,
                firstBtn: "Save",
                secondBtn: "Cancel",
            },
            autoFocus: false,
            restoreFocus: false,
        });

        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                const structureData: StructureUpdateModel = {
                    structureName: result.structureName.trim(),
                    structureDescription: result.structureDescription.trim(),
                    status: result.status,
                };

                this.structureService.updateStructure(+userStructureData?.id!, structureData).subscribe({
                    next: (response) => {
                        this.loadMore.emit(true);
                        this.snackBar.open(response.message, SnackBarConfig.Actions.OKAY, {
                            duration: SnackBarConfig.Duration.MEDIUM,
                        });
                    },
                    error: (error) => {
                        this.snackBar.open(error.error, SnackBarConfig.Actions.OKAY, {
                            duration: SnackBarConfig.Duration.MEDIUM,
                        });
                    },
                });
            }
        });
    }

    public bulkPublishOrDelete(): void {
        const checkedIds = this.getCheckedItems().map((item) => {
            return item.id;
        });
        this.checkboxChange.emit(checkedIds);
    }

    public goToMyStuctureDesign(myStructureId: number): void {
        const structureDetails = this.structuresData.rows.find((structure) => structure.id === myStructureId);

        if (!structureDetails) {
            this.snackBar.open("Structure not found", SnackBarConfig.Actions.OKAY, {
                duration: SnackBarConfig.Duration.MEDIUM,
            });
            return;
        }

        this.router.navigate([`designer/${myStructureId}`]);
    }

    private getCheckedItems(): StructureInstance[] {
        return this.userStructureData.filter((item) => item.isCheckbox);
    }
}

import { Component } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { nameValidator } from "../shared/name-validator.directive";
import { emailValidator } from "../shared/email-validator.directive";
import { EmailService } from "../services/email/email.service";
import { MatSnackBar } from "@angular/material/snack-bar";
import { noSpacesValidator } from "../shared/shared/no-leading-space-validator.directive";
import { AuthService } from "../services/auth/auth.service";
import { SnackBarConfig } from "../constants/snackbar.constants";

@Component({
    selector: "app-contact",
    templateUrl: "./contact.component.html",
    styleUrls: ["./contact.component.scss"],
})
export class ContactComponent {
    public phone = "+917338617766";
    public email = "contact@udmglobal.com";
    public address = `Shilpitha Tech Park, 8th Floor 55/3 55/4, Devarabisanahalli Rd 
  opposite Sakra World Hospital Devarabisanahalli, Bellandur, 
  Bengaluru, Karnataka 560103`;
    public workingHours = {
        weekdays: "9am to 6pm",
        weekends: "Closed",
    };

    public contactForm!: FormGroup;
    public isLogin!: boolean;

    constructor(
        private formBuilder: FormBuilder,
        private emailService: EmailService,
        private snackBar: MatSnackBar,
        private authService: AuthService,
    ) {}

    ngOnInit() {
        this.contactForm = this.formBuilder.group({
            name: ["", [Validators.required, nameValidator(), noSpacesValidator()]],
            email: ["", [Validators.required, emailValidator()]],
            subject: ["", [Validators.required, noSpacesValidator()]],
            message: ["", [Validators.required, noSpacesValidator()]],
        });
    }

    public isInvalid(controlName: string, validatorName?: string): boolean {
        const contorl = this.contactForm.get(controlName);
        if (validatorName) {
            return contorl!.errors?.[validatorName];
        }
        return contorl!.invalid && contorl!.touched;
    }

    private openSnackBar(message: string, action: string): void {
        this.snackBar.open(message, action, {
            ...SnackBarConfig.Defaults,
        });
    }

    private trimSpaces(): void {
        const controlNames = ["name", "subject", "message"];
        controlNames.forEach((controlName) => {
            const control = this.contactForm.get(controlName);
            if (control && control.value) {
                control.setValue(control.value.trim());
            }
        });
    }

    public onSubmit(): void {
        this.isLogin = this.authService.isUserLoggedIn;
        this.trimSpaces();
        const emailData = {
            contactBody: this.contactForm.value,
            isLogin: this.isLogin,
        };
        this.emailService.sendContactEmail(emailData).subscribe({
            next: (response) => {
                this.openSnackBar(response, SnackBarConfig.Actions.OKAY);
            },
            error: (error) => {},
        });
        this.contactForm.reset();
    }
}

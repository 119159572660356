import { Component, OnInit, ViewChild } from "@angular/core";
import { UserService } from "../services/user/user.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { GenericDialogComponent } from "../shared/generic-dialog/generic-dialog.component";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import {
    AddressDetailsCreationAttributes,
    AddressInstance,
    NullableRegisteredUserDetails,
    UserDetailStatus,
} from "@models/user.model";
import { AddressComponent } from "../user-details/address/address.component";
import { appConfig } from "../../environments/app.config";
import { DialogInvokingComponents } from "@models/generic-dialog.model";
import { environment } from "../../environments/environment";
import { ProductService } from "../services/product/product.service";
import { S3UploadHelperService } from "../services/s3-upoad/s3UploadHelper.service";
import { S3UploadService } from "../services/s3-upoad/s3-upload.service";
import { SnackBarConfig } from "../constants/snackbar.constants";

@Component({
    selector: "app-profile",
    templateUrl: "./profile.component.html",
    styleUrls: ["./profile.component.scss"],
})
export class ProfileComponent implements OnInit {
    public userDetails!: NullableRegisteredUserDetails;
    public profileForm!: FormGroup;
    public isEmailValid = true;
    public editMode = false;
    public selectedAddress!: AddressDetailsCreationAttributes;
    public addMode = false;
    public address!: AddressDetailsCreationAttributes;
    public showSubmitButton = false;
    public showEditIcon = true;
    public showDiv = true;
    public showProfileImage!: string | undefined;
    public image!: string;
    public profileLabel = UserDetailStatus;

    @ViewChild(AddressComponent) addressDetailRef: AddressComponent | undefined;

    constructor(
        private userService: UserService,
        private formbuilder: FormBuilder,
        private dialog: MatDialog,
        private _snackBar: MatSnackBar,
        private productService: ProductService,
        private s3UploadService: S3UploadService,
    ) {}

    ngOnInit(): void {
        this.profileForm = this.formbuilder.group({
            name: [{ value: "", disabled: true }, [Validators.required]],
            email: ["", [Validators.required, Validators.email]],
            mobileNumber: [{ value: "", disabled: true }, [Validators.required]],
        });
        this.getUserDetails();
    }

    private getUserDetails(): void {
        this.userService.getUserDetails().subscribe((response) => {
            this.userDetails = response;
            this.showProfileImage = response?.user.profileImage;
            this.patchForm();
        });
    }

    private patchForm(): void {
        this.profileForm.patchValue({
            name: this.userDetails?.user.name,
            email: this.userDetails?.user.email,
            mobileNumber: this.userDetails?.mobileNumber,
        });
    }

    public deleteAddress(address: AddressInstance) {
        this.openDialog(address);
    }

    private openDialog(address: AddressInstance): void {
        const dialogRef = this.dialog.open(GenericDialogComponent, {
            data: {
                componentName: DialogInvokingComponents.DeleteAddress,
                title: "Delete Address",
                content: "Are you sure you want to delete this address?",
                firstBtn: "Delete",
                secondBtn: "Cancel",
            },
            autoFocus: false,
            restoreFocus: false,
        });
        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                this.onDeleteAddress(address.id);
            }
        });
    }

    private onDeleteAddress(id: number): void {
        this.userService.deleteAddress(id).subscribe(
            (response) => {
                this.getUserDetails();
                this.openSnackBar(response, SnackBarConfig.Actions.OKAY);
            },
            (error) => {
                throw new Error(error.message);
            },
        );
    }

    private openSnackBar(message: string, action: string): void {
        this._snackBar.open(message, action, {
            ...SnackBarConfig.Defaults,
        });
    }

    public editAddress(address: AddressDetailsCreationAttributes): void {
        this.editMode = true;
        this.addMode = false;
        this.selectedAddress = address;
    }

    public cancelEdit(): void {
        this.editMode = false;
    }

    public cancelAdd(): void {
        this.addMode = false;
    }

    public async uploadFile(event: Event, fileType: string) {
        const maxImageSize = +appConfig.fileSize.profileImageSize;
        const input = event.target as HTMLInputElement;
        const partSize = 5 * 1024 * 1024;
        if (input.files && fileType === "image") {
            const file = input.files[0];
            if (input.files[0].size < maxImageSize) {
                const parts = Math.ceil(file.size / partSize);
                const partNumbers = Array.from({ length: parts }, (_, i) => i + 1);
                let filePathWithName;
                filePathWithName = `${environment.name}/profileImage/${file.name}`;
                const url = await this.s3UploadService.uploadFile(filePathWithName, partNumbers, file);
                this.userService.uploadProfileImage({ imageUrl: url }).subscribe((uploadResponse) => {
                    this.getUserDetails();
                });
            } else {
                alert(`The maximum allowed file size for images is 5 MB. Please select a smaller file.`);
                return;
            }
        } else {
            const url = this.userDetails?.user.profileImage;
            if (url !== undefined) {
                this.productService.deleteFile(url).subscribe((removeImageFromS3Response) => {
                    if (removeImageFromS3Response) {
                        this.userService.uploadProfileImage({ imageUrl: fileType }).subscribe((response) => {
                            if (response) {
                                this.getUserDetails();
                            }
                        });
                    }
                });
            }
        }
    }

    public toggleAddAddressMode(): void {
        this.addMode = true;
        this.editMode = false;
    }

    public receiveAddressData(address: AddressDetailsCreationAttributes): void {
        this.address = address;
    }

    public onUpdate(): void {
        const body = this.address;
        this.userService.updateAddress(body).subscribe(
            (response) => {
                this.getUserDetails();
                this.openSnackBar(response, SnackBarConfig.Actions.OKAY);
            },
            (error) => {
                throw new Error(error.message);
            },
        );
    }

    public onUpdateEmail(): void {
        const id = this.userDetails!.user.id;
        const email = this.profileForm.get("email")?.value;
        this.userService.updateEmail({ email, id }).subscribe({
            next: (response) => {
                this.getUserDetails();
                this.openSnackBar(response, SnackBarConfig.Actions.OKAY);
            },
            error: (error) => {
                throw new Error(error.message);
            },
        });
    }

    public onAdd(): void {
        const body = this.address;
        this.userService.addAddress(body).subscribe({
            next: (response) => {
                if (response) {
                    this.getUserDetails();
                    this.addMode = false;
                    this.openSnackBar(UserDetailStatus.Add, SnackBarConfig.Actions.CLOSE);
                }
            },
            error: (error) => {
                throw new Error(error.message);
            },
        });
    }

    public isFormDataValid(): boolean {
        return !!this.addressDetailRef?.addressForm.valid;
    }

    public get emailError(): "required" | "invalid" | null {
        const emailControl = this.profileForm.get("email");
        if (emailControl?.errors) {
            if (emailControl.hasError("required")) {
                return "required";
            } else {
                return "invalid";
            }
        }
        return null;
    }
}

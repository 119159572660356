import { Component, OnInit, HostListener, AfterViewInit, ChangeDetectorRef, ViewChild } from "@angular/core";
import { ProductCategories, ProductBody, ProductDetails, ReviewValues } from "@models/product.model";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { ReviewModel, ReviewStatus } from "@models/admin.model";
import { ProductService } from "../services/product/product.service";
import { CartItemService } from "../services/cart-item/cart-item.service";
import { CartItem } from "@models/cart.model";
import { LocalStorageService } from "../services/local-storage/local-storage.service";
import { RightNavbarComponent } from "../dashboard/right-navbar/right-navbar.component";
import { MatDialog } from "@angular/material/dialog";
import { OpenReviewImagesComponent } from "./open-review-images/open-review-images.component";
import { ShapeJson } from "../shared/shape-selector/shape-selector.model";
import { Colors } from "../../models/orderItem.model";
import { WishlistService } from "../services/wishlistService/wishlist.service";
import { AuthService } from "../services/auth/auth.service";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Coupon, CouponsMessage } from "@models/coupon.model";
import { CouponService } from "../services/coupon/coupon.service";
import { selectedShapeColor } from "../../../../utils/shape";
import { ChoiceType, ErrorMessage, faceDetails, faceLabel } from "../shared/models/helper.model";
import { v4 as uuidv4 } from "uuid";
import { combineLatest } from "rxjs";
import { MeshType } from "~/src/utils/shape-type";
import { SnackBarConfig } from "../constants/snackbar.constants";

@Component({
    selector: "app-product-detail-page",
    templateUrl: "./product-detail-page.component.html",
    styleUrls: ["./product-detail-page.component.scss"],
})
export class ProductDetailPageComponent implements OnInit, AfterViewInit {
    @ViewChild(RightNavbarComponent) rightNavbarComponent!: RightNavbarComponent;

    public productId!: number;
    public quantity = 1;
    public imageObject: {
        image: string;
        thumbImage: string;
        video?: string;
    }[] = [];
    public productDetail!: ProductDetails;
    public showAnotherIcon: boolean = false;
    public sliderImageSize = { width: "100%", height: "730px" };
    public data: ProductBody[] = [];
    public cartItems: CartItem[] = [];
    public isProductInCart!: boolean;
    public visibleReviews: number = 5;
    public isFullDescription = false;
    public imageObjectReviewAndRating: Array<{ image: string; thumbImage: string }> = [];
    public isDescriptionMoreThanThreeLines = true;
    public showReadMore = false;
    public imageSize = { width: "100px", height: 100 };
    public activeIndex: number = 0;
    public suggestedProducts: ProductDetails[] = [];
    public currentImageIndex: { [key: number]: number } = {};
    private imageRotations: { [key: number]: any } = {};
    public shapeJson!: ShapeJson;
    public isNavbarOpen: boolean = false;
    public isCategoryBuyBlock: boolean = true;
    private categories = ProductCategories;
    public selectedColor: string = selectedShapeColor.code;
    public colors = Colors;
    public ratingProduct!: ReviewValues[];
    public activeCouponCodes!: object;
    public activeCoupons!: Coupon[];
    public selectedOption = faceDetails;
    public cartUUID!: string;
    public resetSelectedOption = {
        front: "Plane",
        back: "Plane",
        left: "Plane",
        right: "Plane",
        top: "Plane",
        bottom: "Plane",
    };

    constructor(
        private productService: ProductService,
        private route: ActivatedRoute,
        private cartService: CartItemService,
        private router: Router,
        private localStorageService: LocalStorageService,
        private cdr: ChangeDetectorRef,
        private dialog: MatDialog,
        private wishListItemService: WishlistService,
        private snackBar: MatSnackBar,
        private couponService: CouponService,
    ) {}

    ngOnInit(): void {
        combineLatest([this.cartService.getCartItems(), this.route.params]).subscribe(([items, params]) => {
            if (items) {
                this.cartItems = items;
            }

            this.productId = +params["id"];

            if (this.productId) {
                this.loadProducts();
            }
        });

        this.cartService.cartItemDeleted.subscribe((deletedProductId: number) => {
            if (deletedProductId === this.productId) {
                this.isProductInCart = false;
            }
        });
    }

    private loadProducts(): void {
        this.productService.getProductById(this.productId).subscribe((product) => {
            this.productDetail = product;

            this.activeCouponCodes = product.coupon!;
            if (this.activeCouponCodes) {
                this.loadAvailableCouponDetails(this.activeCouponCodes);
            }
            this.ratingProduct = this.productDetail.reviews?.filter(
                (review) => review.status === ReviewStatus.Approved && review.review !== "",
            )!;
            if (this.productDetail.model3DJson) {
                this.shapeJson = this.productDetail.model3DJson as ShapeJson;
            }
            this.isCategoryBuyBlock = !!this.productDetail.category?.includes(this.categories.BuyBlocks);

            this.isProductInCart = this.cartItems.some((item) => item.product!.id === this.productDetail.id);

            this.quantity = this.isCategoryBuyBlock
                ? 1
                : this.localStorageService.getCartedQuantity(this.productId) || 1;

            this.loadImagesAndVideos();
            this.buildImageObject();
            this.fetchSuggestedProducts(this.productDetail?.name, this.productDetail?.id);
        });
    }

    ngAfterViewInit() {
        this.checkDescriptionLines();
        this.updateSliderImageSize();
    }

    private checkDescriptionLines(): void {
        const el = document.querySelector(".description") as HTMLElement;
        if (el) {
            const { lineHeight, borderTop, borderBottom } = window.getComputedStyle(el);
            const contentHeight = el.scrollHeight - parseInt(borderTop) - parseInt(borderBottom);
            const lines = Math.floor(contentHeight / parseInt(lineHeight));

            this.isDescriptionMoreThanThreeLines = lines > 3;
        }
    }

    public loadAvailableCouponDetails(activeCouponCodes: object): void {
        this.couponService.getAvailableCouponDetails(activeCouponCodes).subscribe({
            next: (response) => {
                this.activeCoupons = response;
            },
            error: (error) => {
                throw error.error;
            },
        });
    }

    private loadImagesAndVideos(): void {
        if (this.productDetail && this.productDetail.images) {
            const imagesArray = this.productDetail.images.imagesUrl || [];
            let videoUrls: string[] = [];

            if (typeof this.productDetail.video === "string") {
                try {
                    videoUrls = JSON.parse(this.productDetail.video) as string[];
                } catch (e) {
                    videoUrls = [];
                }
            } else if (Array.isArray(this.productDetail.video)) {
                videoUrls = this.productDetail.video;
            }

            this.imageObject = imagesArray.map((imageUrl) => ({
                image: imageUrl,
                thumbImage: imageUrl,
            }));

            if (Array.isArray(videoUrls)) {
                videoUrls.forEach((videoUrl) => {
                    if (typeof videoUrl === "string" && videoUrl) {
                        const fileExtension = videoUrl.split(".").pop()?.toLowerCase();

                        if (fileExtension === "mp4") {
                            this.imageObject.push({
                                image: videoUrl,
                                thumbImage: videoUrl,
                                video: videoUrl,
                            });
                        } else {
                            throw new Error(`Unsupported video format: ${fileExtension}`);
                        }
                    }
                });
            }
        }

        for (let i = 0; i < this.data.length; i++) {
            this.data.forEach((item: { id: number; quantity: number }) => {
                if (item.id === this.productDetail.id) {
                    this.productDetail.disable = true;
                    this.productDetail.quantity = item.quantity;
                } else {
                    this.productDetail.disable = false;
                }
            });
        }
        this.cdr.detectChanges();
    }

    public isOverflow(element: HTMLElement): boolean {
        return element.clientWidth < element.scrollWidth || element.clientHeight < element.scrollHeight;
    }

    public onInputChange(event: Event, item: ProductDetails): void {
        let inputValueString = (event.target as HTMLInputElement).value;
        const numericalValue = parseInt(inputValueString, 10);
        if (!inputValueString) {
            return;
        }
        if (numericalValue < 1) {
            (event.target as HTMLInputElement).value = "1";
        } else {
            inputValueString = inputValueString.slice(0, 2);
            (event.target as HTMLInputElement).value = inputValueString;
            this.quantity = parseInt(inputValueString, 10);
        }
        this.cartService.updateCartQuantity(item.id, this.quantity, this.cartUUID);
    }

    public checkQuantity(item: ProductBody): void {
        if (!this.quantity) {
            this.quantity = 1;
            this.cartService.updateCartQuantity(item.id, this.quantity, this.cartUUID);
        }
    }

    public onClick(value: number): void {
        this.quantity += value;
        if (this.isProductInCart) {
            if (!this.isCategoryBuyBlock) {
                this.cartService
                    .updateCartQuantity(this.productId, this.quantity, this.cartUUID)
                    .subscribe((res) => {});
            }
        }
        this.rightNavbarComponent.loadCartItems();
    }

    private removeSpaceFromShapeType(shapeType: string): string {
        return shapeType.replace(" ", "");
    }

    public addToCart(): void {
        const shapeType = this.removeSpaceFromShapeType(this.productDetail.name) as MeshType;
        if (this.isCategoryBuyBlock) {
            this.cartUUID = uuidv4();
        }
        const body: CartItem = {
            id: this.productDetail.id,
            quantity: this.quantity,
            ...(this.isCategoryBuyBlock // use for only single block
                ? {
                      singleBlockInfo: {
                          color: this.selectedColor as string,
                          ...this.getFacesForShape(shapeType, this.selectedOption),
                      },
                      cartUUID: this.cartUUID,
                  }
                : {}),
        };
        this.cartService.addToCart(body).subscribe((response) => {
            if (response) {
                this.isProductInCart = true;
                this.isNavbarOpen = true;
                this.rightNavbarComponent.loadCartItems();
                this.resetSelectedOption = {
                    front: "Plane",
                    back: "Plane",
                    left: "Plane",
                    right: "Plane",
                    top: "Plane",
                    bottom: "Plane",
                };
                this.cartUUID = "";
                this.quantity = 1;
            }
        });
    }

    private getFacesForShape(shapeType: MeshType, selectedOption: { [key in faceLabel]: ChoiceType }) {
        switch (shapeType) {
            case MeshType.Cone:
                return {
                    bottom: selectedOption.bottom,
                };
            case MeshType.Cube:
                return {
                    top: selectedOption.top,
                    left: selectedOption.left,
                    right: selectedOption.right,
                    back: selectedOption.back,
                    bottom: selectedOption.bottom,
                    front: selectedOption.front,
                };
            case MeshType.CurvedCube:
                return {
                    back: selectedOption.back,
                    left: selectedOption.left,
                    right: selectedOption.right,
                    bottom: selectedOption.bottom,
                };
            case MeshType.HalfCylinder:
                return {
                    back: selectedOption.back,
                    front: selectedOption.front,
                    bottom: selectedOption.bottom,
                };
            case MeshType.HemiSphere:
                return {
                    bottom: selectedOption.bottom,
                };
            case MeshType.Cylinder:
                return {
                    top: selectedOption.top,
                    bottom: selectedOption.bottom,
                };
            case MeshType.SquarePyramid:
                return {
                    bottom: selectedOption.bottom,
                };
            case MeshType.Prism:
                return {
                    back: selectedOption.back,
                    front: selectedOption.front,
                    bottom: selectedOption.bottom,
                };
            case MeshType.RightTriangle:
                return {
                    left: selectedOption.left,
                    right: selectedOption.right,
                    back: selectedOption.back,
                    bottom: selectedOption.bottom,
                };
            case MeshType.ConcavePrism:
                return {
                    back: selectedOption.back,
                    bottom: selectedOption.bottom,
                };
            default:
                throw new Error(`${ErrorMessage.UnknownShapeType}: ${shapeType}`);
        }
    }

    public onClickBuy(id: number, quantity: number, product: ProductDetails): void {
        const shapeType = this.removeSpaceFromShapeType(product.name) as MeshType;
        let productDetails: CartItem[] = [
            {
                id,
                quantity,
                product,
                ...(this.isCategoryBuyBlock // use for only single block
                    ? {
                          singleBlockInfo: {
                              color: this.selectedColor as string,
                              ...this.getFacesForShape(shapeType, this.selectedOption),
                          },
                      }
                    : {}),
            },
        ];
        this.router
            .navigate(["checkout"], {
                state: { productData: productDetails },
            })
            .then(() => {
                this.resetSelectedOption = {
                    front: "Plane",
                    back: "Plane",
                    left: "Plane",
                    right: "Plane",
                    top: "Plane",
                    bottom: "Plane",
                };
            });
    }

    public goToCart(): void {
        this.router.navigate(["cart"]);
    }

    public showMoreReviews(): void {
        this.visibleReviews += 5;
    }

    public getFilteredReviews(): ReviewModel[] | undefined {
        return this.productDetail.reviews?.filter((review) => review.status === ReviewStatus.Approved && review.review);
    }

    public getStarsArray(rating: number): number[] {
        return Array(rating).fill(0);
    }

    public getTotalRatingsAndReviews(): { validReviewsCount: number; ratingCount: number } {
        let validReviewsCount = 0;
        let ratingCount = 0;

        this.productDetail.reviews?.forEach((review: ReviewModel) => {
            if (review.status === ReviewStatus.Approved) {
                if (review.rating !== null) {
                    ratingCount++;
                }
                if (review.review) {
                    validReviewsCount++;
                }
            }
        });

        return {
            validReviewsCount: validReviewsCount,
            ratingCount: ratingCount,
        };
    }

    public canShowMoreReviews(): boolean {
        if (this.ratingProduct) {
            return this.ratingProduct.length > this.visibleReviews;
        }
        return false;
    }

    public buildImageObject(): void {
        this.imageObjectReviewAndRating = [];
        this.getFilteredReviews()?.forEach((review) => {
            review.images?.imagesUrl?.forEach((url) => {
                this.imageObjectReviewAndRating.push({ image: url, thumbImage: url });
            });
        });
    }

    public readMore(): void {
        this.isFullDescription = true;
    }

    public readLess(): void {
        this.isFullDescription = false;
    }

    @HostListener("window:resize", ["$event"])
    public onResize(event: Event): void {
        this.checkDescriptionLines();
        this.updateSliderImageSize();
    }

    private updateSliderImageSize(): void {
        const screenWidth = window.innerWidth;
        let height = "";

        if (screenWidth <= 600) {
            // Small screens
            height = "300px";
        } else if (screenWidth <= 900) {
            // Medium screens
            height = "500px";
        } else {
            // Large screens
            height = "730px";
        }

        this.sliderImageSize.height = height;

        // Optionally, force change detection
        setTimeout(() => {
            this.cdr.detectChanges();
        }, 0);
    }

    public getRatingClass(rating: number): string {
        return `rating-${rating}`;
    }

    private fetchSuggestedProducts(productName: string, productId: number): void {
        this.productService.suggestedProduct(productName, productId).subscribe({
            next: (response) => {
                this.suggestedProducts = response;
            },
        });
    }

    public startImageRotation(productId: number): void {
        this.currentImageIndex[productId] = 0;
        const images = this.suggestedProducts.find((p) => p.id === productId)?.images?.imagesUrl || [];
        if (images.length > 1) {
            this.imageRotations[productId] = setInterval(() => {
                this.currentImageIndex[productId] = (this.currentImageIndex[productId] + 1) % images.length;
            }, 2000);
        }
    }

    public stopImageRotation(productId: number): void {
        if (this.imageRotations[productId]) {
            clearInterval(this.imageRotations[productId]);
            delete this.imageRotations[productId];
            this.currentImageIndex[productId] = 0; // Reset to the first image
        }
    }

    public getCurrentImageUrl(productId: number): string {
        const product = this.suggestedProducts.find((p) => p.id === productId);
        if (product && product.images && product.images.imagesUrl.length > 0) {
            const index = this.currentImageIndex[productId] || 0;
            return product.images.imagesUrl[index];
        }
        return "";
    }

    public onClickProduct(productId: number): void {
        this.router.navigate([`products/${productId}`]);
    }

    public getProductQtyInCart(productId: number, cartUUID?: string): number {
        return this.localStorageService.getCartedQuantity(productId, cartUUID);
    }

    public onclick(value: number, productId: number): void {
        const quantityInCart = this.getProductQtyInCart(productId, this.cartUUID);
        const newQuantity = quantityInCart + value;
        this.updateCartQuantity(productId, newQuantity, this.cartUUID);
    }

    private updateCartQuantity(productId: number, quantity: number, cartUUID?: string): void {
        if (quantity < 1) {
            this.removeProductFromCart(productId, cartUUID!);
        } else if (quantity <= 99) {
            this.updateProductQuantityInCart(productId, quantity, cartUUID!);
        }
    }

    private removeProductFromCart(id: number, cartUUID: string): void {
        const item = { id, cartUUID };
        this.cartService.removeCartItem(item).subscribe(() => {});
    }

    private updateProductQuantityInCart(productId: number, quantity: number, cartUUID: string): void {
        this.cartService.updateCartQuantity(productId, quantity, cartUUID).subscribe(() => {});
    }

    public onBlur(event: Event, productId: number): void {
        const inputValue = (event.target as HTMLInputElement).value.trim();
        if (!inputValue) {
            (event.target as HTMLInputElement).value = "1";
            const quantity = parseInt((event.target as HTMLInputElement).value, 10);
            this.updateCartQuantity(productId, quantity, this.cartUUID);
        }
    }

    public addToCartSuggested(productId: number, quantity: number) {
        const item: CartItem = {
            id: productId,
            cartUUID: this.cartUUID,
            quantity: quantity,
        };
        this.cartService.addToCart(item).subscribe(() => {
            this.isNavbarOpen = true;
            this.cartUUID = "";
            this.rightNavbarComponent.loadCartItems();
        });
    }

    public onCloseNavbar() {
        this.isNavbarOpen = false;
    }

    public openReviewImages(reviewData: ReviewModel): void {
        this.dialog.open(OpenReviewImagesComponent, {
            width: "100vw",
            height: "(100vh - 70px)",
            data: {
                reviewProductData: reviewData,
            },
        });
    }

    public getSelectedColor(color: string): void {
        this.selectedColor = color;
    }

    public getSeletedChoice(data: { [key in faceLabel]: ChoiceType }): void {
        this.selectedOption = data;
    }

    public addToFavorite(product: ProductDetails): void {
        this.wishListItemService.addToFavorite(product);
    }

    public isInWishlist(product: ProductDetails): boolean {
        return this.wishListItemService.isInWishlist(product);
    }

    private openSnackBar(message: string, action: string): void {
        this.snackBar.open(message, action, {
            ...SnackBarConfig.Defaults,
        });
    }

    public copyToClipboard(couponCode: string) {
        navigator.clipboard.writeText(couponCode).then(() => {
            this.openSnackBar(CouponsMessage.Copied, SnackBarConfig.Actions.OKAY);
        });
    }
}

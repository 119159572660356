import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { CartItemService } from "../../services/cart-item/cart-item.service";
import { CartItem } from "@models/cart.model";
import { CartDetails, ProductDetails } from "@models/product.model";
import { LocalStorageService } from "../../services/local-storage/local-storage.service";
import { Router } from "@angular/router";
import { GenericDialogComponent } from "../../shared/generic-dialog/generic-dialog.component";
import { MatDialog } from "@angular/material/dialog";
import { DialogInvokingComponents } from "@models/generic-dialog.model";
import { appConfig } from "../../../environments/app.config";
import { Colors } from "../../../models/orderItem.model";

@Component({
    selector: "app-right-navbar",
    templateUrl: "./right-navbar.component.html",
    styleUrls: ["./right-navbar.component.scss"],
})
export class RightNavbarComponent implements OnInit {
    @Input() isOpen: boolean = false;
    @Output() closeEvent = new EventEmitter<void>();
    public cartItems: CartItem[] = [];
    public products: CartDetails[] = [];
    public cartId!: number;
    public totalMRP!: number;
    public totalDiscount!: number;
    public totalAmount!: number;
    public minQuantity = appConfig.minProductQuantity;
    public maxQuantity = appConfig.maxProductQuantity;
    public isCheckoutEnabled: boolean = false;
    public minOrderValue = appConfig.minOrderValue;
    public colors = Colors;

    constructor(
        private dialog: MatDialog,
        private cartService: CartItemService,
        private cartItemService: CartItemService,
        private localStorageService: LocalStorageService,
        private router: Router,
    ) {}

    ngOnInit(): void {
        this.loadCartItems();
    }

    public loadCartItems(): void {
        this.cartItemService.getCartItems().subscribe((items) => {
            this.cartItems = items;
            this.products = this.cartItems.map((item) => ({
                product: item.product!,
                singleBlockInfo: item.singleBlockInfo!,
                cartUUID: item.cartUUID,
            }));
            if (this.products.length === 0) {
                this.closeNavbar();
                return;
            }
            this.calculateTotalValues();
        });
    }

    closeNavbar() {
        this.isOpen = false;
        this.closeEvent.emit();
    }

    public onDeleteCartItem(item: CartDetails): void {
        this.openDialog(item);
    }

    private openDialog(item: CartDetails): void {
        const dialogRef = this.dialog.open(GenericDialogComponent, {
            data: {
                componentName: DialogInvokingComponents.RemoveFromCart,
                title: "Remove from Cart",
                image: item.product.images?.imagesUrl[0],
                content: "Are you sure you want to remove this item from cart?",
                firstBtn: "Remove",
            },
            autoFocus: false,
            restoreFocus: false,
        });

        dialogRef.afterClosed().subscribe((result) => {
            if (result) this.removeProductFromCart(item.product.id, item.cartUUID!);
        });
    }

    private calculateTotalValues(): void {
        this.cartItemService.calculateTotalMRP(this.cartItems);
        this.totalMRP = this.cartItemService.totalMRP;
        this.totalDiscount = this.cartItemService.totalDiscount;
        this.totalAmount = this.cartItemService.totalAmount;
        this.isCheckoutEnabled = this.totalAmount >= appConfig.minOrderValue;
    }

    public getProductQtyInCart(productId: number, cartUUID: string): number {
        return this.localStorageService.getCartedQuantity(productId, cartUUID);
    }

    private updateCartQuantity(productId: number, quantity: number, cartUUID: string): void {
        if (quantity < 1) {
            this.removeProductFromCart(productId, cartUUID);
        } else if (quantity <= 99) {
            this.updateProductQuantityInCart(productId, quantity, cartUUID);
        }
    }

    private removeProductFromCart(id: number, cartUUID: string): void {
        const item = { id, cartUUID };
        this.cartService.removeCartItem(item).subscribe(() => {
            this.loadCartItems();
        });
    }

    private updateProductQuantityInCart(productId: number, quantity: number, cartUUID: string): void {
        this.cartService.updateCartQuantity(productId, quantity, cartUUID).subscribe(() => {
            this.loadCartItems();
        });
    }

    public onClick(value: number, productId: number, cartUUID: string): void {
        const quantityInCart = this.getProductQtyInCart(productId, cartUUID);
        const newQuantity = quantityInCart + value;
        this.updateCartQuantity(productId, newQuantity, cartUUID);
        this.loadCartItems();
    }

    public onBlur(event: Event, productId: number, cartUUID: string): void {
        const inputValue = (event.target as HTMLInputElement).value.trim();
        if (!inputValue) {
            (event.target as HTMLInputElement).value = "1";
            const quantity = parseInt((event.target as HTMLInputElement).value, 10);
            this.updateCartQuantity(productId, quantity, cartUUID);
        }
    }

    public onInputChange(event: Event, productId: number, cartUUID: string): void {
        let inputValue = (event.target as HTMLInputElement).value.trim();
        let quantity = parseInt(inputValue, 10);

        if (isNaN(quantity)) {
            return;
        } else if (quantity < 1) {
            (event.target as HTMLInputElement).value = "1";
            quantity = parseInt((event.target as HTMLInputElement).value, 10);
        } else if (inputValue.length > 2) {
            inputValue = inputValue.slice(0, 2);
            (event.target as HTMLInputElement).value = inputValue;
            return;
        }

        this.updateCartQuantity(productId, quantity, cartUUID);
    }

    public onClickProduct(productId: number): void {
        this.router.navigate([`products/${productId}`]);
    }

    public onCheckout(): void {
        this.router.navigate(["checkout"]);
    }

    public extractFacesByType(data: CartDetails, type: string): string[] {
        return this.cartItemService.getFacesByType(data, type);
    }

    public getShapeColorName(data: CartDetails): string {
        return this.cartItemService.getColorName(data);
    }

    public getShapeColorValue(data: CartDetails): string {
        return this.cartItemService.getColorValue(data);
    }
}

import { HttpClient } from "@angular/common/http";
import { EventEmitter, Injectable } from "@angular/core";
import { Observable, Subject, map, of } from "rxjs";
import { environment } from "../../../environments/environment";
import { AuthService } from "../auth/auth.service";
import { CartItem, ResponseMessage } from "@models/cart.model";
import { CartDetails, ProductDetails } from "@models/product.model";
import { LocalStorageService } from "../local-storage/local-storage.service";
import { Colors } from "@models/orderItem.model";

@Injectable({
    providedIn: "root",
})
export class CartItemService {
    public cartItemDeleted = new EventEmitter<number>();
    public colors = Colors;

    constructor(
        private http: HttpClient,
        private authService: AuthService,
        private localStorageService: LocalStorageService,
    ) {}

    public addToCart(item: CartItem): Observable<string> {
        this.localStorageService.addItemToCart(item);

        if (this.authService.isUserLoggedIn) {
            return this.http.post<string>(`${environment.apiUrlBasePath}/carts/add`, item);
        }
        return of(ResponseMessage.AddSuccess);
    }

    public getCartItems(): Observable<CartItem[]> {
        if (this.authService.isUserLoggedIn) {
            const response = this.http.get<CartItem[]>(`${environment.apiUrlBasePath}/carts/getCartItems`);
            return response;
        } else {
            const cartItems = this.localStorageService.getCartItems();
            const cartItemsIds = cartItems.map((item) => item.id);
            return this.http
                .post<ProductDetails[]>(`${environment.apiUrlBasePath}/products/getProductsByIds`, cartItemsIds)
                .pipe(
                    map((products) => {
                        // Map products to cart items
                        return cartItems.map((item) => ({
                            id: item.id,
                            cartUUID: item.cartUUID,
                            quantity: item.quantity,
                            product: products.find((product: { id: number }) => product.id === item.id), // Find corresponding product
                            singleBlockInfo: item.singleBlockInfo,
                        }));
                    }),
                );
        }
    }

    public updateCartQuantity(productId: number, quantity: number, cartUUID?: string): Observable<string> {
        this.localStorageService.updateCartQuantity(productId, quantity, cartUUID!);
        if (this.authService.isUserLoggedIn) {
            const body = { productId, cartUUID, quantity };
            return this.http.put<string>(`${environment.apiUrlBasePath}/carts/update`, body);
        } else {
            return of(ResponseMessage.UpdateSuccess);
        }
    }

    public removeCartItem(item: { id: number; cartUUID?: string }): Observable<string> {
        const body = { cartUUID: item.cartUUID };
        this.localStorageService.removeCartItem(item.id, item.cartUUID!);
        this.cartItemDeleted.emit(item.id);
        if (this.authService.isUserLoggedIn) {
            return this.http.post(`${environment.apiUrlBasePath}/carts/delete/${item.id}`, body) as Observable<string>;
        } else {
            return of(ResponseMessage.Delete);
        }
    }

    public removeAllCartItems(): Observable<string> {
        this.localStorageService.removeAllCartItems();

        if (this.authService.isUserLoggedIn) {
            return this.http.delete(`${environment.apiUrlBasePath}/carts/removeAll`) as Observable<string>;
        } else {
            return of(ResponseMessage.Delete);
        }
    }

    public processCartItems(): void {
        const oldCartItems = this.localStorageService.getCartItems();

        this.getCartItems().subscribe((savedItems) => {
            if (!oldCartItems.length) {
                // No items in local storage
                if (savedItems.length) {
                    const updateItem = savedItems.map(this.mapToCartItem);
                    this.localStorageService.setCartItems(updateItem);
                }
            } else {
                // Sync local storage
                const updatedItems = savedItems.map(this.mapToCartItem);
                this.localStorageService.setCartItems(updatedItems);
                this.processItems(oldCartItems, updatedItems);
            }
        });
    }

    private mapToCartItem(item: CartItem): CartItem {
        return {
            id: item.product!.id,
            cartUUID: item.cartUUID,
            quantity: item.quantity,
            singleBlockInfo: item.singleBlockInfo,
        };
    }

    private processItems(oldItems: CartItem[], updatedItems: CartItem[]): void {
        const [ordinaryItems, singleBlockItems] = this.partitionItems(oldItems);

        this.processOrdinaryItems(ordinaryItems, updatedItems); // Ordinary product
        this.processSingleBlockItems(singleBlockItems, updatedItems); // singleBlock item
    }

    private partitionItems(items: CartItem[]): [CartItem[], CartItem[]] {
        return [items.filter((item) => !item.singleBlockInfo), items.filter((item) => item.singleBlockInfo)];
    }

    // Ordinary product method
    private processOrdinaryItems(ordinaryItems: CartItem[], updatedItems: CartItem[]): void {
        ordinaryItems.forEach((oldItem) => {
            const existingItem = updatedItems.find((item) => item.id === oldItem.id);

            if (existingItem) {
                if (existingItem.quantity !== oldItem.quantity && !existingItem.singleBlockInfo) {
                    this.updateCartQuantity(oldItem.id, oldItem.quantity, oldItem.cartUUID!).subscribe();
                }
            } else {
                this.addToCart(oldItem).subscribe();
            }
        });
    }

    //SingleBlock Item method
    private processSingleBlockItems(singleBlockItems: CartItem[], updatedItems: CartItem[]): void {
        const updatedBlockItems = updatedItems.filter((item) => item.singleBlockInfo);

        singleBlockItems.forEach((item) => {
            const exists = updatedBlockItems.some((ele) => ele.cartUUID === item.cartUUID);
            if (!exists) {
                this.addToCart(item).subscribe();
            }
        });
    }

    totalMRP: number = 0;
    totalDiscount: number = 0;
    totalAmount: number = 0;

    public calculateTotalMRP(items: CartItem[]): void {
        this.totalMRP = 0;
        this.totalDiscount = 0;
        this.totalAmount = 0;
        for (let item of items) {
            const quantity = item.quantity;
            const productTotalPrice = item.product!.price * quantity;
            const productTotalDiscount =
                (item.product!.discountPrice ? item.product!.price - item.product!.discountPrice : 0) * quantity;
            this.totalMRP += productTotalPrice;
            this.totalDiscount += productTotalDiscount;
            this.totalAmount = this.totalMRP - this.totalDiscount;
        }
    }

    public getFacesByType(data: CartDetails, type: string): string[] {
        const faceArray = [];
        for (const [key, value] of Object.entries(data.singleBlockInfo!)) {
            if (value === type) {
                faceArray.push(key);
            }
        }
        return faceArray;
    }

    public getColorName(data: CartDetails): string {
        const color = data.singleBlockInfo!.color;
        const currentColor = this.colors.find((colorValue) => colorValue.value === color);
        return currentColor!.name;
    }

    public getColorValue(data: CartDetails): string {
        const color = data.singleBlockInfo!.color;
        const currentColor = this.colors.find((colorValue) => colorValue.value === color);
        return currentColor!.value;
    }
}

import { Component, EventEmitter, Input, Output, OnDestroy, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { ProductCategories, ProductDetails } from "@models/product.model";
import { CartItemService } from "../../services/cart-item/cart-item.service";
import { CartItem } from "@models/cart.model";
import { LocalStorageService } from "../../services/local-storage/local-storage.service";
import { RightNavbarComponent } from "../../dashboard/right-navbar/right-navbar.component";
import { WishlistService } from "../../services/wishlistService/wishlist.service";

@Component({
    selector: "product-viewlist",
    templateUrl: "./product-viewlist.component.html",
    styleUrls: ["./product-viewlist.component.scss"],
})
export class ProductViewlistComponent implements OnDestroy {
    @Input() productList: ProductDetails[] = [];
    @Input() showButton: boolean = true;
    @Output() viewMoreClick: EventEmitter<void> = new EventEmitter<void>();

    @ViewChild(RightNavbarComponent) rightNavbarComponent!: RightNavbarComponent;

    public product!: ProductDetails;
    public cartItems: CartItem[] = [];
    public isNavbarOpen: boolean = false;
    public products: ProductDetails[] = [];
    public productCategories = ProductCategories;
    public currentImageIndex: { [key: number]: number } = {};
    private imageRotations: { [key: number]: any } = {};
    private categories = ProductCategories;

    constructor(
        private router: Router,
        private cartService: CartItemService,
        private localStorageService: LocalStorageService,
        private wishListItemService: WishlistService,
    ) {}

    public startImageRotation(productId: number): void {
        if (window.innerWidth <= 768) {
            return; // Disable rotation for screens smaller than 768px
        }

        this.currentImageIndex[productId] = 0;
        const images = this.productList.find((p) => p.id === productId)?.images?.imagesUrl || [];
        if (images.length > 1) {
            this.imageRotations[productId] = setInterval(() => {
                this.currentImageIndex[productId] = (this.currentImageIndex[productId] + 1) % images.length;
            }, 2000);
        }
    }

    public stopImageRotation(productId: number): void {
        if (window.innerWidth <= 768) {
            return;
        }

        if (this.imageRotations[productId]) {
            clearInterval(this.imageRotations[productId]);
            delete this.imageRotations[productId];
            this.currentImageIndex[productId] = 0; // Reset to the first image
        }
    }

    public getProductQtyInCart(productId: number): number {
        return this.localStorageService.getCartedQuantity(productId);
    }

    public onclick(value: number, productId: number): void {
        const quantityInCart = this.getProductQtyInCart(productId);
        const newQuantity = quantityInCart + value;
        this.updateCartQuantity(productId, newQuantity);
        if (!this.isNavbarOpen) {
            this.isNavbarOpen = true;
        }
        this.rightNavbarComponent.loadCartItems();
    }

    public updateQuantity(value: number, productId: number): void {
        const quantityInCart = this.getProductQtyInCart(productId);
        const newQuantity = Math.max(1, Math.min(99, quantityInCart + value));

        if (newQuantity === 0) {
            this.removeProductFromCart(productId);
        } else {
            this.cartService.updateCartQuantity(productId, newQuantity).subscribe();
        }
    }

    public onInputChange(event: Event, productId: number): void {
        let inputValue = (event.target as HTMLInputElement).value.trim();
        let quantity = parseInt(inputValue, 10);

        if (isNaN(quantity)) {
            return;
        } else if (quantity < 1) {
            (event.target as HTMLInputElement).value = "1";
            quantity = parseInt((event.target as HTMLInputElement).value, 10);
        } else if (inputValue.length > 2) {
            inputValue = inputValue.slice(0, 2);
            (event.target as HTMLInputElement).value = inputValue;
            return;
        }

        this.updateCartQuantity(productId, quantity);
    }

    public onBlur(event: Event, productId: number): void {
        const inputValue = (event.target as HTMLInputElement).value.trim();
        if (!inputValue) {
            (event.target as HTMLInputElement).value = "1";
            const quantity = parseInt((event.target as HTMLInputElement).value, 10);
            this.updateCartQuantity(productId, quantity);
        }
    }

    private updateCartQuantity(productId: number, quantity: number): void {
        if (quantity < 1) {
            this.removeProductFromCart(productId);
        } else if (quantity <= 99) {
            this.updateProductQuantityInCart(productId, quantity);
        }
    }

    private removeProductFromCart(id: number): void {
        const item = { id };
        this.cartService.removeCartItem(item).subscribe(() => {
            this.isNavbarOpen = true;
            this.rightNavbarComponent.loadCartItems();
        });
    }

    private updateProductQuantityInCart(productId: number, quantity: number): void {
        this.cartService.updateCartQuantity(productId, quantity).subscribe(() => {
            this.rightNavbarComponent.loadCartItems();
        });
    }

    public onClickProduct(productId: number): void {
        this.router.navigate([`products/${productId}`]);
    }

    public onViewMoreClick(): void {
        this.viewMoreClick.emit();
    }

    public addToCart(productId: number, quantity: number) {
        const item: CartItem = {
            id: productId,
            quantity: quantity,
        };
        this.cartService.addToCart(item).subscribe(() => {
            this.isNavbarOpen = true;
            this.rightNavbarComponent.loadCartItems();
        });
    }

    public addToFavorite(product: ProductDetails): void {
        this.wishListItemService.addToFavorite(product);
    }

    public isInWishlist(product: ProductDetails): boolean {
        return this.wishListItemService.isInWishlist(product);
    }

    public onCloseNavbar() {
        this.isNavbarOpen = false;
    }

    public getCurrentImageUrl(productId: number): string {
        const product = this.productList.find((p) => p.id === productId);
        if (product && product.images && product.images.imagesUrl.length > 0) {
            const index = this.currentImageIndex[productId] || 0;
            return product.images.imagesUrl[index];
        }
        return "";
    }

    public onCustomize(productId: number): void {
        this.router.navigate(["products", productId]);
    }

    ngOnDestroy() {
        // Clean up intervals to prevent memory leaks
        Object.values(this.imageRotations).forEach(clearInterval);
    }

    public isCategoryBuyBlocks(category?: string[] | undefined): boolean {
        return Array.isArray(category) && category.includes(this.categories.BuyBlocks);
    }
}

import { Component, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { NotificationService } from "./notification.service";
import { UserService } from "../services/user/user.service";
import { environment } from "../../environments/environment";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import { GenericDialogComponent } from "../shared/generic-dialog/generic-dialog.component";
import { DialogInvokingComponents } from "@models/generic-dialog.model";
import { Router } from "@angular/router";
import { MatCheckboxChange } from "@angular/material/checkbox";
import { finalize } from "rxjs";
import { Mail, NotificationMessage } from "@models/notification.model";
import { MatSnackBar } from "@angular/material/snack-bar";
import { SnackBarConfig } from "../constants/snackbar.constants";

interface Notification {
    id: number;
    userId: number;
    emailBody: string;
    message: string;
    type: string;
    isMessageViewed: boolean;
    mailStatus: string;
    createdAt: string;
    updatedAt: string;
    isDeleted?: boolean;
}

@Component({
    selector: "app-user-notification",
    templateUrl: "./user-notification.component.html",
    styleUrls: ["./user-notification.component.scss"],
})
export class UserNotificationComponent implements OnInit {
    public notifications: Notification[] = [];
    public pageIndex = environment.dynamicPagination.pageIndex;
    public pageSize = environment.dynamicPagination.pageSize;
    public userId!: number;
    public totalNotifications = 0;
    public selectedNotifications: Set<number> = new Set<number>();
    public loading: boolean = true;
    public notificationCount!: number;
    public unreadNotificationCount: number = 0;
    public mail = Mail;

    constructor(
        private notificationService: NotificationService,
        private userService: UserService,
        private dialog: MatDialog,
        private sanitizer: DomSanitizer,
        private router: Router,
        private snackBar: MatSnackBar,
    ) {}

    ngOnInit(): void {
        this.userService.getUserDetails().subscribe((response) => {
            if (response) {
                this.userId = response.id;
                this.loadNotifications(this.userId);
            }
        });
    }

    public loadNotifications(userId: number): void {
        this.loading = true;
        this.notificationService
            .getNotifications(this.pageIndex, this.pageSize, userId)
            .pipe(
                finalize(() => {
                    this.loading = false; // Will run whether it's an error or success
                }),
            )
            .subscribe({
                next: (response) => {
                    this.notificationCount = response.count;
                    this.notifications = [...this.notifications, ...response.rows] as Notification[]; // Append new notifications
                    this.updateTotalNotifications(response.count); // Update the total count of notifications
                    this.unreadNotificationCount = response.totalUnreadCount; // total unread count
                },
                error: (error) => {
                    throw error; // Handle the error
                },
            });
    }

    private updateTotalNotifications(total: number): void {
        this.totalNotifications = total; // Store the total notifications count
    }

    public markNotificationAsViewed(notificationId: number): void {
        this.notificationService.markAsViewednotification(notificationId).subscribe(() => {
            const notification = this.notifications.find((notification) => notification.id === notificationId);
            if (notification) {
                notification.isMessageViewed = true;

                // Decrement the unread notification count
                this.unreadNotificationCount = Math.max(0, this.unreadNotificationCount - 1);
            }
        });
    }

    public openMailPopup(notification: Notification): void {
        const emailBody = JSON.parse(notification.emailBody);
        const safeHtml: SafeHtml = this.sanitizer.bypassSecurityTrustHtml(emailBody);

        this.dialog.open(GenericDialogComponent, {
            data: {
                componentName: DialogInvokingComponents.EmailMessage,
                title: "Notification",
                message: safeHtml,
            },
        });
    }

    public loadMoreNotifications(): void {
        if (this.pageIndex * this.pageSize < this.totalNotifications) {
            this.pageIndex++;
            this.loadNotifications(this.userId);
        }
    }

    public showViewMoreButton(): boolean {
        return this.notifications.length < this.totalNotifications && this.notifications.length >= this.pageSize;
    }

    public deleteNotification(id: number): void {
        // Pass a single notification ID as an array for deletion
        this.openDeleteConfirmationDialog([id]);
    }

    public deleteSelectedNotifications(): void {
        // Handle multiple deletions by passing the selected IDs
        const idsToDelete = Array.from(this.selectedNotifications);
        this.openDeleteConfirmationDialog(idsToDelete);
    }

    private confirmDeleteNotifications(ids: number[]): void {
        this.notificationService.deleteNotifications(ids).subscribe({
            next: () => {
                this.notifications = this.notifications.filter((notification) => !ids.includes(notification.id));
                this.totalNotifications -= ids.length;
                this.selectedNotifications.clear();

                if (this.notifications.length === 0) {
                    this.loadNotifications(this.userId);
                }

                const message =
                    ids.length > 1
                        ? NotificationMessage.SelectedNotificationsDeletedsuccessfully
                        : NotificationMessage.Deletedsuccessfully;

                this.snackBar.open(message, SnackBarConfig.Actions.CLOSE, {
                    ...SnackBarConfig.Defaults,
                });
            },
            error: () => {
                this.snackBar.open(NotificationMessage.FailedToDelete, SnackBarConfig.Actions.CLOSE, {
                    ...SnackBarConfig.Defaults,
                });
            },
        });
    }

    private openDeleteConfirmationDialog(ids: number[]): void {
        // Generic dialog for both single and multiple notification deletion
        const dialogRef = this.dialog.open(GenericDialogComponent, {
            data: {
                componentName: DialogInvokingComponents.RemoveFromNotification,
                title: ids.length > 1 ? "Delete Notifications" : "Delete Notification",
                content:
                    ids.length > 1
                        ? "Are you sure you want to delete the selected notifications?"
                        : "Are you sure you want to delete this notification?",
                firstBtn: "Yes",
                secondBtn: "Cancel",
            },
            autoFocus: false,
            restoreFocus: false,
        });

        // Use a single method to confirm the deletion for both cases
        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                this.confirmDeleteNotifications(ids);
            }
        });
    }

    public toggleSelectNotification(id: number): void {
        if (this.selectedNotifications.has(id)) {
            this.selectedNotifications.delete(id);
        } else {
            this.selectedNotifications.add(id);
        }
    }

    public selectAllNotifications(): void {
        if (this.notifications.length < this.totalNotifications) {
            this.notificationService.getNotifications(0, this.totalNotifications, this.userId).subscribe((response) => {
                response.rows.forEach((notification: any) => {
                    this.selectedNotifications.add(notification.id);
                });
            });
        } else {
            this.notifications.forEach((notification: Notification) => {
                this.selectedNotifications.add(notification.id);
            });
        }
    }

    public deselectAllNotifications(): void {
        this.selectedNotifications.clear();
    }

    public isAllSelected(): boolean {
        return (
            this.notifications.length > 0 &&
            this.notifications.every((notification) => this.selectedNotifications.has(notification.id))
        );
    }

    public isSomeSelected(): boolean {
        return this.selectedNotifications.size > 0 && !this.isAllSelected();
    }

    public toggleSelectAll(event: MatCheckboxChange): void {
        if (event.checked) {
            this.selectAllNotifications();
        } else {
            this.deselectAllNotifications();
        }
    }

    public goToHomePage(): void {
        this.router.navigate(["/home"]);
    }

    public getUnreadNotificationCount(): number {
        return this.unreadNotificationCount;
    }
}

import { ChangeDetectorRef, Component, OnInit, inject } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { MatDialog } from "@angular/material/dialog";
import { ProductBody, ProductDetails } from "@models/product.model";
import { DialogSaveDataModel, OrderPageDetail, PaymentType } from "src/models/order.model";
import { RatingAndReviewComponent } from "./rating-and-review/rating-and-review.component";
import { OrderStatus, status } from "../../models/order.model";
import { OrderService } from "../services/order/order.service";
import { AdminStatusHistoryModel } from "@models/adminStatusHistory.model";
import { UserService } from "../services/user/user.service";
import { ReviewModel } from "@models/admin.model";
import { environment } from "src/environments/environment";
import { PaymentStatus, RefundStatus } from "@models/payment.model";
import { GenericDialogComponent } from "../shared/generic-dialog/generic-dialog.component";
import { MatSnackBar } from "@angular/material/snack-bar";
import { DialogInvokingComponents } from "@models/generic-dialog.model";
import { OrderItem } from "@models/orderItem.model";
import { finalize } from "rxjs";
import { MatCheckboxChange } from "@angular/material/checkbox";
import { SnackBarConfig } from "../constants/snackbar.constants";

@Component({
    selector: "app-order-details",
    templateUrl: "./order-details.component.html",
    styleUrls: ["./order-details.component.scss"],
})
export class OrderDetailsComponent implements OnInit {
    public order!: OrderPageDetail;
    public filteredStatusHistory?: AdminStatusHistoryModel[] = [];
    public showTimeline!: boolean;
    public currency = environment.currency.indianRupee;
    public totalProducts: number | undefined;
    public subtotal: number | undefined;
    readonly dialog = inject(MatDialog);
    public orderStatusHistory: AdminStatusHistoryModel[] = [];
    public orderStatusTrack!: status[];
    public showTrack!: boolean;
    public getUserId!: number;
    public OrderStatus = OrderStatus;
    public currentOrder: OrderPageDetail | undefined;
    public currentOrderItem!: OrderItem;
    public returnReason!: string;
    public exchangeReason!: string;
    public showReturnPopup = false;
    public orderID!: string;
    public selectedItemIds: number[] = [];
    public cancelledItems: OrderItem[] = [];
    public nonCancelledItems: OrderItem[] = [];
    public returnedItems: OrderItem[] = [];
    public exchangeItems: OrderItem[] = [];
    public hasCancelledItems: boolean = false;
    public hasReturnedItems: boolean = false;
    public hasExchangeItems: boolean = false;
    public refundStatus = RefundStatus;
    public isAllRefundSuccessful: boolean = false;
    public isSelectAllChecked = false;
    public loading: boolean = true;
    public exceedMoreThan7daysIds!: number[];
    public paymentType = PaymentType;

    constructor(
        private orderService: OrderService,
        private route: ActivatedRoute,
        private userService: UserService,
        private router: Router,
        private cdr: ChangeDetectorRef,
        private snackBar: MatSnackBar,
    ) {}

    ngOnInit(): void {
        this.getOrderDetailsbyId();
        this.getOrderIdsExceedMoreThan7days();
    }

    public getOrderDetailsbyId(): void {
        this.orderID = this.route.snapshot.params["id"];

        this.orderService
            .getOrderById(this.orderID)
            .pipe(
                finalize(() => {
                    this.loading = false;
                }),
            )
            .subscribe({
                next: (response) => {
                    this.loading = true;
                    if (response === null) {
                        this.router.navigate(["/noAccess"]);
                    }
                    this.order = response;

                    this.cancelledItems = this.order?.orderItems?.filter(
                        (item) => item.itemStatus === OrderStatus.Cancelled,
                    )!;

                    this.nonCancelledItems = this.order?.orderItems?.filter(
                        (item) =>
                            item.itemStatus !== OrderStatus.Cancelled &&
                            item.itemStatus !== OrderStatus.ReturnInitiated &&
                            item.itemStatus !== OrderStatus.PickupScheduled &&
                            item.itemStatus !== OrderStatus.Returned &&
                            item.itemStatus !== OrderStatus.ExchangeInitiated &&
                            item.itemStatus !== OrderStatus.ExchangePacked &&
                            item.itemStatus !== OrderStatus.ExchangeDispatched &&
                            item.itemStatus !== OrderStatus.Exchanged,
                    )!;

                    this.returnedItems = this.order.orderItems?.filter(
                        (item) =>
                            item.itemStatus === OrderStatus.ReturnInitiated ||
                            item.itemStatus === OrderStatus.PickupScheduled ||
                            item.itemStatus === OrderStatus.Returned,
                    )!;

                    this.exchangeItems = this.order.orderItems?.filter(
                        (item) =>
                            item.itemStatus === OrderStatus.ExchangeInitiated ||
                            item.itemStatus === OrderStatus.ExchangePacked ||
                            item.itemStatus === OrderStatus.ExchangeDispatched ||
                            item.itemStatus === OrderStatus.Exchanged,
                    )!;

                    this.hasCancelledItems = this.order?.orderItems?.some(
                        (item) => item.itemStatus === OrderStatus.Cancelled,
                    )!;

                    this.hasReturnedItems = this.order.orderItems?.some(
                        (item) =>
                            item.itemStatus === OrderStatus.ReturnInitiated ||
                            item.itemStatus === OrderStatus.PickupScheduled ||
                            item.itemStatus === OrderStatus.Returned,
                    )!;

                    this.hasExchangeItems = this.order.orderItems?.some(
                        (item) =>
                            item.itemStatusHistory?.some(
                                (itemStatusHistory) =>
                                    itemStatusHistory.status === OrderStatus.ExchangeInitiated ||
                                    itemStatusHistory.status === OrderStatus.ExchangePacked ||
                                    itemStatusHistory.status === OrderStatus.ExchangeDispatched ||
                                    itemStatusHistory.status === OrderStatus.Exchanged,
                            ),
                    )!;

                    this.isAllRefundSuccessful = this.order?.orderItems?.every(
                        (item) => item.refund !== null && item.refund?.refundStatus === RefundStatus.RefundSuccessful,
                    )!;

                    this.cdr.detectChanges();

                    this.filteredStatusHistory = this.order.orderStatusHistory?.filter((history) => {
                        return history.status !== OrderStatus.Pending && history.status !== OrderStatus.Failed;
                    });

                    if (this.filteredStatusHistory?.length) {
                        this.showTimeline = true;
                    }
                },
                error: (error) => {
                    throw error;
                },
            });
        this.userService.getUserDetails().subscribe((response) => {
            if (response) {
                this.getUserId = response.id;
            }
        });
    }

    get refundStatusMessage(): string {
        return this.order?.orderItems?.every(
            (item) => item.refund !== null && item.refund?.refundStatus === RefundStatus.RefundSuccessful,
        )
            ? RefundStatus.RefundSuccessful
            : "";
    }

    public getOrderStatusClasses(item?: OrderItem): { [key: string]: boolean } {
        return {
            failed: this.order.orderStatus === OrderStatus.Failed,
            pending: this.order.orderStatus === OrderStatus.Pending,
            ordered: this.order.orderStatus === OrderStatus.Ordered,
            packed: this.order.orderStatus === OrderStatus.Packed,
            cancelled: this.order.orderStatus === OrderStatus.Cancelled,
            dispatched: this.order.orderStatus === OrderStatus.Dispatched,
            delivered: this.order.orderStatus === OrderStatus.Delivered,
            returnInitiated:
                this.order.orderStatus === OrderStatus.ReturnInitiated ||
                item?.itemStatus === OrderStatus.ReturnInitiated,
            pickupScheduled:
                this.order.orderStatus === OrderStatus.PickupScheduled ||
                item?.itemStatus === OrderStatus.PickupScheduled,
            returned: this.order.orderStatus === OrderStatus.Returned || item?.itemStatus === OrderStatus.Returned,
            exchangeInitiated:
                this.order.orderStatus === OrderStatus.ExchangeInitiated ||
                item?.itemStatus === OrderStatus.ExchangeInitiated,
            exchangePacked:
                this.order.orderStatus === OrderStatus.ExchangePacked ||
                item?.itemStatus === OrderStatus.ExchangePacked,
            exchangeDispatched:
                this.order.orderStatus === OrderStatus.ExchangeDispatched ||
                item?.itemStatus === OrderStatus.ExchangeDispatched,
            exchanged: this.order.orderStatus === OrderStatus.Exchanged || item?.itemStatus === OrderStatus.Exchanged,
        };
    }

    // Example method to dynamically set classes based on payment status
    public getPaymentStatusClasses(): { [key: string]: boolean } {
        return {
            pending: this.order.paymentStatus === PaymentStatus.Pending,
            success: this.order.paymentStatus === PaymentStatus.Success,
            failed: this.order.paymentStatus === PaymentStatus.Failed,
            cod: this.order.paymentStatus === PaymentType.CashOnDelivery,
        };
    }

    public getRefundStatusClass(refundStatus: string | undefined): string {
        return this.orderService.getRefundStatusClass(refundStatus);
    }

    public isCancellableOrReturnOrder(order: OrderPageDetail): string {
        const isDeliveredOrExchanged =
            order.orderStatus === OrderStatus.Delivered || order.orderStatus === OrderStatus.Exchanged;

        // Show "Return" only if the order is Delivered/Exchanged and no return is initiated
        if (isDeliveredOrExchanged && !this.hasReturnedItems) {
            return "Return";
        }

        // Show "Cancel" if the order can still be cancelled and no exchange or return exists
        if (
            ![
                OrderStatus.Dispatched,
                OrderStatus.Cancelled,
                OrderStatus.ReturnInitiated,
                OrderStatus.Returned,
                OrderStatus.PickupScheduled,
                OrderStatus.Pending, // Exclude Pending status from cancellation
            ].includes(order.orderStatus as OrderStatus) &&
            !this.hasCancelledItems &&
            !this.hasExchangeItems &&
            !this.hasReturnedItems
        ) {
            return "Cancel";
        }

        return "";
    }

    public isOverflow(el: HTMLElement): boolean {
        return el.clientWidth < el.scrollWidth || el.clientHeight < el.scrollHeight;
    }

    public isReviewDisabled(product: ProductDetails | undefined): boolean {
        if (!this.order || !this.order.reviews) {
            return true;
        }

        return this.order.reviews.some(
            (review: ReviewModel) => review.productId === product?.id && review.userId === this.getUserId,
        );
    }

    public openRatingPopup(product: ProductBody | undefined, order: OrderPageDetail): void {
        const dialogRef = this.dialog.open(RatingAndReviewComponent, {
            disableClose: true,
            width: "450px",
            data: {
                product_details: product,
                order_details: order,
                userId: this.getUserId,
            },
        });
        dialogRef.afterClosed().subscribe((result) => {
            if (result === true) {
                setTimeout(() => {
                    this.getOrderDetailsbyId();
                }, 1000);
            }
        });
    }

    public toggleItemSelection(itemId: number, isChecked: boolean): void {
        if (isChecked) {
            // Add product ID to the list
            this.selectedItemIds.push(itemId);
        } else {
            // Remove product ID from the list
            this.selectedItemIds = this.selectedItemIds.filter((id) => id !== itemId);
        }
    }

    public isSelected(itemId: number): boolean {
        return this.selectedItemIds.includes(itemId);
    }

    public isAllSelected(): boolean {
        let allItemIds;
        if (this.nonCancelledItems.length > 0) {
            allItemIds = this.nonCancelledItems?.map((item) => item.id);
        } else {
            allItemIds = this.exchangeItems?.map((item) => item.id);
        }

        return allItemIds?.every((id) => this.selectedItemIds.includes(id!))!; // The every() method executes a function for each array element.
    }

    public isSomeSelected(): boolean {
        return this.selectedItemIds.length > 0 && !this.isAllSelected();
    }

    // Toggle select/deselect all items
    public toggleSelectAll(event: MatCheckboxChange): void {
        this.isSelectAllChecked = event.checked;

        if (event.checked) {
            if (this.nonCancelledItems.length > 0 && this.exchangeItems.length > 0) {
                this.selectedItemIds =
                    this.order.orderItems?.map((item) => item.id).filter((id): id is number => id !== undefined) || [];
            } else {
                this.selectedItemIds =
                    this.nonCancelledItems.length > 0
                        ? this.nonCancelledItems.map((item) => item.id).filter((id): id is number => id !== undefined)
                        : this.exchangeItems?.map((item) => item.id).filter((id): id is number => id !== undefined) ||
                          [];
            }
        } else {
            this.selectedItemIds = [];
        }
    }

    private openDialog(): void {
        const dialogRef = this.dialog.open(GenericDialogComponent, {
            data: {
                componentName: DialogInvokingComponents.CancelOrder,
                title: "Cancel Item",
                content: "Selected item will be cancelled, and the amount will be refunded within 5-7 working days",
                firstBtn: "OK",
                secondBtn: "Cancel",
            },
            autoFocus: false,
            restoreFocus: false,
        });

        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                this.confirmCanceSelectedItem();
            }
        });
    }

    private confirmCanceSelectedItem(): void {
        this.orderService.cancelSelectedItem(this.orderID, this.selectedItemIds).subscribe((response) => {
            if (response) {
                this.isSelectAllChecked = false;
                this.selectedItemIds = [];
                this.getOrderDetailsbyId();
                this.snackBar.open(response.message, SnackBarConfig.Actions.CLOSE, {
                    duration: SnackBarConfig.Duration.MEDIUM,
                });
            }
        });
    }

    public cancelOrReturn(order: OrderPageDetail): void {
        if (order.orderStatus === OrderStatus.Delivered) {
            if (order.paymentStatus === PaymentType.CashOnDelivery) {
                this.openDialogReturnCoD();
            } else {
                this.openDialogReturn();
            }
        } else if (order.orderStatus !== OrderStatus.Delivered && order.orderStatus !== OrderStatus.Dispatched) {
            this.openDialog();
        }
    }

    public openDialogReturn(): void {
        const dialogRef = this.dialog.open(GenericDialogComponent, {
            data: {
                componentName: DialogInvokingComponents.ReturnOrder,
                title: "Return Order",
                content: "Please provide your feedback for returning this order:",
                inputs: [
                    {
                        controlName: "reason",
                        value: "",
                        placeholder: "Reason for Returning",
                        type: "text",
                    },
                ],
                firstBtn: "Return Order",
                secondBtn: "Cancel",
            },
            autoFocus: false,
            restoreFocus: false,
        });
        dialogRef.componentInstance.saveClicked.subscribe((savedData: DialogSaveDataModel) => {
            if (savedData) {
                this.confirmReturnSelectedItem(savedData);
            }
        });
    }

    public openDialogReturnCoD(): void {
        const dialogRef = this.dialog.open(GenericDialogComponent, {
            data: {
                componentName: DialogInvokingComponents.ReturnOrderCod,
                title: "Return Order",
                content: "Please provide your feedback for returning this order:",
                content2: "Select your option",
                firstBtn: "Return Order",
                secondBtn: "Cancel",
            },
            autoFocus: false,
            restoreFocus: false,
        });
        dialogRef.componentInstance.saveClicked.subscribe((savedData: DialogSaveDataModel) => {
            if (savedData) {
                this.confirmReturnSelectedItem(savedData);
            }
        });
    }

    private confirmReturnSelectedItem(data: DialogSaveDataModel): void {
        this.orderService
            .returnSelectedItem(
                this.orderID,
                this.selectedItemIds,
                data.reason,
                data.refundOption,
                data.bankDetails?.accountHolderName,
                data.bankDetails?.accountNumber,
                data.bankDetails?.ifscCode,
                data.upi?.upiId,
            )
            .subscribe((response) => {
                if (response) {
                    this.isSelectAllChecked = false;
                    this.selectedItemIds = [];
                    this.getOrderDetailsbyId();
                    this.snackBar.open(response.message, SnackBarConfig.Actions.CLOSE, {
                        duration: SnackBarConfig.Duration.MEDIUM,
                    });
                }
            });
    }

    public openExchangeDialog(order: OrderPageDetail): void {
        this.currentOrder = order;
        const dialogRef = this.dialog.open(GenericDialogComponent, {
            data: {
                componentName: DialogInvokingComponents.ExchangeMessage,
                title: "Exchange items",
                content: "Please provide your feedback for exchanging these items:",
                inputs: [
                    {
                        label: "Message",
                        placeholder: "Enter Your Query...",
                        controlName: "exchangeQuery",
                        type: "text",
                    },
                ],
                firstBtn: "Exchange",
                secondBtn: "Cancel",
            },
            autoFocus: false,
            restoreFocus: false,
        });

        dialogRef.componentInstance.saveClicked.subscribe((savedData: any) => {
            if (savedData) {
                this.confirmExchangeSelectedItem(order, savedData.exchangeQuery);
            }
        });
    }

    public confirmExchangeSelectedItem(order: OrderPageDetail, reason: string): void {
        this.exchangeReason = reason;
        this.orderService.exchangeSelectedItem(this.orderID, this.selectedItemIds, this.exchangeReason).subscribe({
            next: (response) => {
                this.isSelectAllChecked = false;
                this.selectedItemIds = [];
                this.getOrderDetailsbyId();
                this.snackBar.open(response.message, SnackBarConfig.Actions.CLOSE, {
                    duration: SnackBarConfig.Duration.MEDIUM,
                });
            },
            error: (error) => {
                throw error;
            },
        });
    }

    public isCancellable(order: OrderPageDetail): boolean {
        return [OrderStatus.Packed, OrderStatus.Ordered].includes(order.orderStatus as OrderStatus);
    }

    public isReturnable(order: OrderPageDetail): boolean {
        return [
            OrderStatus.Delivered,
            OrderStatus.ReturnInitiated,
            OrderStatus.PickupScheduled,
            OrderStatus.Returned,
        ].includes(order.orderStatus as OrderStatus);
    }

    public getOrderIdsExceedMoreThan7days(): void {
        this.orderService.getOrderExceed7days().subscribe({
            next: (response) => {
                this.exceedMoreThan7daysIds = response;
            },
            error: (error) => {
                throw error.error;
            },
        });
    }

    public isExceedMoreThan7days(order: OrderPageDetail): boolean {
        return this.exceedMoreThan7daysIds?.includes(order?.id);
    }

    public showReviewBtn(item: OrderItem): boolean {
        return (
            item.itemStatus === OrderStatus.Delivered ||
            item.itemStatus === OrderStatus.ReturnInitiated ||
            item.itemStatus === OrderStatus.PickupScheduled ||
            item.itemStatus === OrderStatus.Returned
        );
    }

    public isItemExchanged(): boolean {
        return this.exchangeItems.some((item) => item.itemStatus === OrderStatus.Exchanged);
    }
}

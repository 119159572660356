import {
    Component,
    ElementRef,
    EventEmitter,
    HostListener,
    Input,
    OnChanges,
    OnDestroy,
    Output,
    SimpleChanges,
    ViewChild,
} from "@angular/core";
import * as THREE from "three";
import { CSG } from "three-csg-ts";
import { ColorCodes } from "@utils/color-constants";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js";
import { HalfScreenCanvasConfig } from "@utils/canvas-configuration";
import { MatSnackBar } from "@angular/material/snack-bar";
import { FaceVertices, RemoveNode, ValidFacesRange } from "@utils/shape-facetype";
import { ShapeJson } from "../shared/shape-selector/shape-selector.model";
import { ChoiceType, faceDetails, faceLabel, myChoices } from "../shared/models/helper.model";
import { CylinderConfiguration } from "../shared/models/shape.config.model";
import { ShapeHelperService } from "../services/shape-helper/shape-helper.service";
import { SceneManagerService } from "../services/scene-manager/scene-manager.service";
import { MeshType } from "~/src/utils/shape-type";
import { SnackBarConfig } from "../constants/snackbar.constants";

interface MeshWithChoice extends THREE.Mesh {
    choice?: string;
}
@Component({
    selector: "app-block-details-component",
    templateUrl: "./block-details-component.component.html",
    styleUrls: ["./block-details-component.component.scss"],
})
export class BlockDetailsComponentComponent implements OnDestroy, OnChanges {
    @ViewChild("canvasRef", { static: true }) canvasRef!: ElementRef<HTMLCanvasElement>;

    private orbitControl!: OrbitControls;
    private objects: THREE.Object3D[] = [];
    public choices = myChoices.choices;
    public selectedChoice!: ChoiceType;
    private scene!: THREE.Scene;
    private camera!: THREE.PerspectiveCamera;
    private renderer!: THREE.WebGLRenderer;
    private canvas!: HTMLCanvasElement;
    private animationFrameId: number | null = null;
    private shape: THREE.Mesh | null = null;
    private currentShape!: MeshType;
    public finalMesh!: THREE.Mesh;
    public nodeOrHoleMesh!: MeshWithChoice;
    private raycaster = new THREE.Raycaster();
    private mousePosition = new THREE.Vector2();
    public faceName = faceDetails;
    private directionalLight!: THREE.DirectionalLight;

    @Input() shapeJson!: ShapeJson;
    @Input() selectedColor!: THREE.ColorRepresentation | string;
    @Input() resetSelectedOption!: any;
    @Output() seletedchoice = new EventEmitter<{ [key in faceLabel]: ChoiceType }>();

    constructor(
        private snackBar: MatSnackBar,
        private shapeHelperService: ShapeHelperService,
        private sceneManagerService: SceneManagerService,
    ) {}

    ngOnInit(): void {
        if (!this.shapeJson) {
            return;
        }

        if (this.resetSelectedOption) {
            this.faceName = this.resetSelectedOption;
        }

        this.currentShape = this.shapeJson.object.name;
        this.initThreeScene();

        window.addEventListener("resize", this.onResize.bind(this));
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.selectedColor = this.selectedColor;
        if (this.shape) {
            (this.shape as any).material.color = new THREE.Color(this.selectedColor as THREE.ColorRepresentation);
        }
    }

    private initThreeScene() {
        // Create scene
        this.scene = new THREE.Scene();
        this.scene.background = new THREE.Color(ColorCodes.sceneBackground);

        this.rendererConfiguration();

        this.cameraConfiguration();
        this.lightConfiguration();
        this.controlsConfiguration();

        this.createShape();

        // Start animation
        this.animate();
    }

    private animate() {
        this.animationFrameId = requestAnimationFrame(() => this.animate());
        //Update controls, required when enableDamping is true
        this.orbitControl.update();

        this.directionalLight?.position.copy(this.camera.position);

        this.renderer.render(this.scene, this.camera);
    }

    private onResize = () => {
        // Get the latest canvas element
        this.canvas = this.canvasRef.nativeElement;

        // Get the parent container's dimensions
        const containerRect = this.canvas.parentElement?.getBoundingClientRect();

        if (containerRect) {
            // Update canvas dimensions to match container
            this.canvas.width = containerRect.width;
            this.canvas.height = containerRect.height;

            // Update camera aspect ratio
            this.camera.aspect = this.canvas.width / this.canvas.height;
            this.camera.updateProjectionMatrix();

            // Resize renderer
            this.renderer.setSize(this.canvas.width, this.canvas.height);
        }
    };

    private rendererConfiguration(): void {
        this.canvas = this.canvasRef.nativeElement;
        // Create renderer
        this.renderer = new THREE.WebGLRenderer({ canvas: this.canvas, alpha: true, antialias: true });
        this.renderer.setPixelRatio(window.devicePixelRatio);
        this.renderer.setSize(this.canvas.clientWidth, this.canvas.clientHeight);
    }

    private cameraConfiguration(): void {
        // Create camera
        this.camera = new THREE.PerspectiveCamera(
            HalfScreenCanvasConfig.perspectiveCameraFov,
            this.canvas.clientWidth / this.canvas.clientHeight,
            HalfScreenCanvasConfig.perspectiveCameraNearField,
            HalfScreenCanvasConfig.perspectiveCameraFarField,
        );

        this.camera.position.set(
            HalfScreenCanvasConfig.cameraPosition_X_Cooridinate,
            HalfScreenCanvasConfig.cameraPosition_Y_Cooridinate,
            HalfScreenCanvasConfig.cameraPosition_Z_Cooridinate,
        );
    }

    private lightConfiguration(): void {
        this.directionalLight = new THREE.DirectionalLight(0xffffff, HalfScreenCanvasConfig.directionalLightValue);
        this.directionalLight.position.copy(this.camera.position);
        this.scene.add(this.directionalLight);

        const ambientLight = new THREE.AmbientLight(ColorCodes.pointLight, HalfScreenCanvasConfig.ambientLightValue);
        this.scene.add(ambientLight);
    }

    private controlsConfiguration(): void {
        this.orbitControl = new OrbitControls(this.camera, this.renderer.domElement);

        const {
            orbitControlMaxDistance,
            orbitControlTarget_X_Coordinate,
            orbitControlTarget_Y_Coordinate,
            orbitControlTarget_Z_Coordinate,
            oribitControlPanSpeed,
            orbitControlDynamicDampingFactor,
        } = HalfScreenCanvasConfig;

        Object.assign(this.orbitControl, {
            maxDistance: orbitControlMaxDistance,
            target: new THREE.Vector3(
                orbitControlTarget_X_Coordinate,
                orbitControlTarget_Y_Coordinate,
                orbitControlTarget_Z_Coordinate,
            ),
            panSpeed: oribitControlPanSpeed,
            enableDamping: true,
            dynamicDampingFactor: orbitControlDynamicDampingFactor,
        });

        this.orbitControl.enableZoom = false;
    }

    // creating shape
    private createShape(): void {
        const isShapePresent = !!this.shapeJson;
        if (isShapePresent) {
            this.currentShape = this.shapeJson.object.name;
            this.shape =
                this.sceneManagerService.createMeshForBlock(
                    this.shapeJson,
                    isShapePresent,
                    this.selectedColor,
                    false,
                ) ?? null;
            if (this.shape) {
                this.finalMesh = this.shape.clone();
                this.scene.add(this.shape);
                this.currentShape = this.shape.name as MeshType;
                this.shapeFaceLabel();
            }
        }
    }

    private shapeFaceLabel(): void {
        if (
            [
                MeshType.CurvedCube,
                MeshType.HalfCylinder,
                MeshType.Cylinder,
                MeshType.Cube,
                MeshType.SquarePyramid,
                MeshType.Prism,
                MeshType.Cone,
                MeshType.HemiSphere,
                MeshType.RightTriangle,
                MeshType.ConcavePrism,
            ].includes(this.currentShape)
        ) {
            const transparentMesh = this.sceneManagerService.createMeshForBlock(
                this.shapeJson.object.userData["faceMesh"],
                false,
                "",
                false,
            );
            if (transparentMesh) {
                this.objects.push(transparentMesh);
                this.scene.add(transparentMesh);
            }
        }
    }

    @HostListener("dblclick", ["$event"])
    onDoubleClick(event: MouseEvent): void {
        this.seletedchoice.emit(this.faceName);
        const elementBoundingRect = this.renderer.domElement.getBoundingClientRect();
        this.mousePosition.x = ((event.clientX - elementBoundingRect.left) / elementBoundingRect.width) * 2 - 1;
        this.mousePosition.y = -((event.clientY - elementBoundingRect.top) / elementBoundingRect.height) * 2 + 1;
        this.raycaster.setFromCamera(this.mousePosition, this.camera);
        const intersects = this.raycaster.intersectObjects(this.scene.children, true);

        if (intersects.length > 0) {
            const intersectedObject = intersects[0];
            if (intersectedObject.object instanceof THREE.Mesh || (intersectedObject.object as THREE.LineSegments)) {
                const mesh = intersectedObject.object as THREE.Mesh;
                this.handleObject(mesh, intersectedObject);
            }
        } else {
            this.snackBar.open("No intersections found", SnackBarConfig.Actions.CLOSE, {
                duration: SnackBarConfig.Duration.MEDIUM,
            });
        }
    }

    private handleObject(mesh: THREE.Mesh, intersectedObject: THREE.Intersection): void {
        //range of each face of the shape to be appeared
        const faceRanges: Record<string, number[][]> = {
            HalfCylinder: [[ValidFacesRange.HalfCylinderMinimum, ValidFacesRange.HalfCylinderMaximum]],
            HemiSphere: [[ValidFacesRange.HemiSphereMinimum, ValidFacesRange.HemiSphereMaximum]],
            Cylinder: [[ValidFacesRange.CylinderMinimum, ValidFacesRange.CylinderMaximum]],
            CurvedCube: [
                [ValidFacesRange.CurvedCubeMinimum, ValidFacesRange.CurvedCubeMaximum],
                [ValidFacesRange.CurvedCubeSecondRangeMin, ValidFacesRange.CurvedCubeSecondRangeMax],
            ],
            SquarePyramid: [[ValidFacesRange.SquarePyramidMinimum, ValidFacesRange.SquarePyramidMaximum]],
            Cone: [[ValidFacesRange.ConeMinimum, ValidFacesRange.ConeMaximum]],
            Prism: [
                [ValidFacesRange.PrismFirstRangeMin, ValidFacesRange.PrismFirstRangeMax],
                [ValidFacesRange.PrismSecondRangeMin, ValidFacesRange.PrismSecondRangeMax],
            ],
            Cube: [[ValidFacesRange.CubeMinimum, ValidFacesRange.CubeMaximum]],
            RightTriangle: [
                [ValidFacesRange.RightTriangleFirstMinRange, ValidFacesRange.RightTriangleFirstMaxRange],
                [ValidFacesRange.RightTriangleSecondMinRange, ValidFacesRange.RightTriangleSecondMaxRange],
            ],
            ConcavePrism: [
                [ValidFacesRange.ConcavePrismFirstMinRange, ValidFacesRange.ConcavePrismFirstMaxRange],
                [ValidFacesRange.ConcavePrismSecondMinRange, ValidFacesRange.ConcavePrismSecondMaxRange],
            ],
        };

        const faceIndex = intersectedObject.faceIndex;

        if (faceIndex !== undefined) {
            const ranges = faceRanges[this.currentShape] || [];
            const isValidFace = ranges.some(([min, max]) => faceIndex >= min && faceIndex <= max);

            if (isValidFace) {
                this.handleObjectNodeAndHole(mesh, intersectedObject);
            } else {
                this.snackBar.open("Invalid face", SnackBarConfig.Actions.CLOSE, {
                    duration: SnackBarConfig.Duration.MEDIUM,
                });
            }
        }
    }

    private handleObjectNodeAndHole(mesh: THREE.Mesh, intersect: THREE.Intersection): void {
        if (mesh.geometry instanceof THREE.BoxGeometry) {
            const geometry = new THREE.CylinderGeometry(
                CylinderConfiguration.radiusTop,
                CylinderConfiguration.radiusBottom,
                CylinderConfiguration.height,
                CylinderConfiguration.radialSegment,
            );

            const material = new THREE.MeshStandardMaterial({
                color: this.selectedColor as THREE.ColorRepresentation,
            });
            const bufferToBoxGeometry = mesh.geometry as THREE.BoxGeometry;
            const size = bufferToBoxGeometry.parameters.width / 2;

            const faceCenters = [
                new THREE.Vector3(0, 0, size), // Front face
                new THREE.Vector3(0, 0, -size), // Back face
                new THREE.Vector3(size, 0, 0), // Right face
                new THREE.Vector3(-size, 0, 0), // Left face
                new THREE.Vector3(0, size, 0), // Top face
                new THREE.Vector3(0, -size, 0), // Bottom face
            ];

            if (intersect && intersect.face && intersect.face.normal) {
                const faceNormal = intersect.face.normal;
                const faceIndex = this.getFaceNameFromNormal(faceNormal);
                if (faceIndex !== -1) {
                    this.nodeOrHoleMesh = new THREE.Mesh(geometry, material);
                    this.nodeOrHoleMesh.castShadow = true;
                    this.nodeOrHoleMesh.receiveShadow = true;
                    this.nodeOrHoleMesh.position.copy(faceCenters[faceIndex]).add(this.finalMesh.position);
                    if (faceIndex === 5 && this.currentShape === MeshType.HemiSphere) {
                        this.nodeOrHoleMesh.position.y += 1 / 2;
                    } else if (faceIndex === 5 && this.currentShape === MeshType.SquarePyramid) {
                        this.nodeOrHoleMesh.position.y += 3.5 / 2;
                    } else if (faceIndex === 5 && this.currentShape === MeshType.HalfCylinder) {
                        this.nodeOrHoleMesh.position.y += 2 / 2;
                    } else if (this.currentShape === MeshType.RightTriangle) {
                        if (faceIndex === 0) {
                            const vertex1 = new THREE.Vector3(
                                -FaceVertices.RightTriangleVerticePoint,
                                FaceVertices.RightTriangleVerticePoint,
                                FaceVertices.RightTriangleVerticePoint,
                            );
                            const vertex2 = new THREE.Vector3(
                                -FaceVertices.RightTriangleVerticePoint,
                                -FaceVertices.RightTriangleVerticePoint,
                                FaceVertices.RightTriangleVerticePoint,
                            );
                            const vertex3 = new THREE.Vector3(
                                FaceVertices.RightTriangleVerticePoint,
                                -FaceVertices.RightTriangleVerticePoint,
                                FaceVertices.RightTriangleVerticePoint,
                            );
                            const center = this.shapeHelperService.getCenterForEachFace(vertex1, vertex2, vertex3);
                            this.nodeOrHoleMesh.position.copy(center);
                        } else if (faceIndex === 1) {
                            const vertex1 = new THREE.Vector3(
                                -FaceVertices.RightTriangleVerticePoint,
                                FaceVertices.RightTriangleVerticePoint,
                                -FaceVertices.RightTriangleVerticePoint,
                            );
                            const vertex2 = new THREE.Vector3(
                                -FaceVertices.RightTriangleVerticePoint,
                                -FaceVertices.RightTriangleVerticePoint,
                                -FaceVertices.RightTriangleVerticePoint,
                            );
                            const vertex3 = new THREE.Vector3(
                                FaceVertices.RightTriangleVerticePoint,
                                -FaceVertices.RightTriangleVerticePoint,
                                -FaceVertices.RightTriangleVerticePoint,
                            );
                            const center = this.shapeHelperService.getCenterForEachFace(vertex1, vertex2, vertex3);
                            this.nodeOrHoleMesh.position.copy(center);
                        }
                    } else if (faceIndex === 3 && this.currentShape === MeshType.ConcavePrism) {
                        this.nodeOrHoleMesh.position.x -= HalfScreenCanvasConfig.nodeHoleConcavePrismX;
                    } else if (faceIndex === 5 && this.currentShape === MeshType.ConcavePrism) {
                        this.nodeOrHoleMesh.position.y -= HalfScreenCanvasConfig.nodeHoleConcavePrismX;
                    } else if (this.currentShape === MeshType.Prism) {
                        if (faceIndex === 4) {
                            const vertex1 = new THREE.Vector3(
                                FaceVertices.PrismVerticePointcX,
                                FaceVertices.PrismVerticePointcY,
                                FaceVertices.PrismVerticePointcZ,
                            );
                            const vertex2 = new THREE.Vector3(
                                -FaceVertices.PrismVerticePointcX,
                                FaceVertices.PrismVerticePointcY,
                                -FaceVertices.PrismVerticePointcZ,
                            );
                            const vertex3 = new THREE.Vector3(
                                FaceVertices.PrismVerticePointcX,
                                FaceVertices.PrismVerticePointcY,
                                -FaceVertices.PrismVerticePointcZ,
                            );
                            const center = this.shapeHelperService.getCenterForEachFace(vertex1, vertex2, vertex3);
                            this.nodeOrHoleMesh.position.copy(center);
                        } else if (faceIndex === 5) {
                            const vertex1 = new THREE.Vector3(
                                FaceVertices.PrismVerticePointcX,
                                -FaceVertices.PrismVerticePointcY,
                                FaceVertices.PrismVerticePointcZ,
                            );
                            const vertex2 = new THREE.Vector3(
                                FaceVertices.PrismVerticePointcX,
                                -FaceVertices.PrismVerticePointcY,
                                -FaceVertices.PrismVerticePointcZ,
                            );
                            const vertex3 = new THREE.Vector3(
                                -FaceVertices.PrismVerticePointcX,
                                -FaceVertices.PrismVerticePointcY,
                                -FaceVertices.PrismVerticePointcZ,
                            );
                            const center = this.shapeHelperService.getCenterForEachFace(vertex1, vertex2, vertex3);
                            this.nodeOrHoleMesh.position.copy(center);
                        }
                    }

                    const axis = new THREE.Vector3().crossVectors(new THREE.Vector3(0, 1, 0), faceNormal).normalize();
                    const angle = Math.acos(new THREE.Vector3(0, 1, 0).dot(faceNormal));
                    this.nodeOrHoleMesh.rotation.setFromRotationMatrix(
                        new THREE.Matrix4().makeRotationAxis(axis, angle),
                    );

                    this.nodeOrHoleMesh.updateMatrix();
                    /****************  NODE AND HOLE METHOD  *******************/
                    // Initialize `nodeOrHole` user data if it doesn't exist
                    if (this.selectedChoice !== undefined) {
                        if (!this.finalMesh.userData["nodeOrHole"]) {
                            this.finalMesh.userData["nodeOrHole"] = [];
                        }

                        let combinedMesh!: THREE.Mesh;

                        if (this.selectedChoice === "Node") {
                            combinedMesh = CSG.union(this.finalMesh, this.nodeOrHoleMesh);
                            this.nodeOrHoleMesh.choice = "node";
                        } else if (this.selectedChoice === "Hole") {
                            combinedMesh = CSG.subtract(this.finalMesh, this.nodeOrHoleMesh);
                            this.nodeOrHoleMesh.choice = "hole";
                        }

                        if (combinedMesh) {
                            combinedMesh.userData["nodeOrHole"] = this.finalMesh.userData["nodeOrHole"];
                            combinedMesh.userData["nodeOrHole"].push(this.nodeOrHoleMesh);
                            this.finalMesh = combinedMesh;
                            this.scene.children = this.scene.children.filter((item) => item.id === combinedMesh.id);
                            this.scene.add(this.finalMesh);
                        }
                        this.lightConfiguration();
                        this.shapeFaceLabel();
                    } else {
                        this.snackBar.open("Please Select Your Choice", SnackBarConfig.Actions.CLOSE, {
                            duration: SnackBarConfig.Duration.MEDIUM,
                        });
                    }

                    /**************** PLANE  METHOD *******************/

                    if (this.selectedChoice === "Plane") {
                        let meshWithPlane = this.shape?.clone()!;

                        const faceName = faceCenters[faceIndex];
                        if (faceName.equals(new THREE.Vector3(0, -3.05, 0))) {
                            //hemisphere

                            this.finalMesh.userData["nodeOrHole"] = this.finalMesh.userData["nodeOrHole"].filter(
                                (cylinderMesh: THREE.Mesh) =>
                                    cylinderMesh.position.equals(new THREE.Vector3(0, -3.05, 0)),
                            );
                        }
                        if (faceName.equals(new THREE.Vector3(0, -5.05, 0))) {
                            // squarepyramid

                            this.finalMesh.userData["nodeOrHole"] = this.finalMesh.userData["nodeOrHole"].filter(
                                (cylinderMesh: THREE.Mesh) =>
                                    !cylinderMesh.position.equals(new THREE.Vector3(0, -3.3, 0)),
                            );
                        }

                        if (faceName.equals(new THREE.Vector3(0, -3.05, 0))) {
                            // half cylinder
                            this.finalMesh.userData["nodeOrHole"] = this.finalMesh.userData["nodeOrHole"].filter(
                                (cylinderMesh: THREE.Mesh) =>
                                    !cylinderMesh.position.equals(new THREE.Vector3(0, -2.05, 0)),
                            );
                        }

                        if (
                            faceName.equals(
                                new THREE.Vector3(
                                    RemoveNode.RightTriangleNodeX,
                                    RemoveNode.RightTriangleNodeY,
                                    RemoveNode.RightTriangleNodeZ,
                                ),
                            )
                        ) {
                            // right triangle left face
                            this.filterNodeOrHole.call(
                                this,
                                faceName,
                                new THREE.Vector3(
                                    -RemoveNode.RightTrianglePlaneX,
                                    -RemoveNode.RightTrianglePlaneY,
                                    RemoveNode.RightTrianglePlaneZ,
                                ),
                            );
                        }

                        if (
                            faceName.equals(
                                new THREE.Vector3(
                                    RemoveNode.RightTriangleNodeX,
                                    RemoveNode.RightTriangleNodeY,
                                    -RemoveNode.RightTriangleNodeZ,
                                ),
                            )
                        ) {
                            // right triangle right face
                            this.filterNodeOrHole.call(
                                this,
                                faceName,
                                new THREE.Vector3(
                                    -RemoveNode.RightTrianglePlaneX,
                                    -RemoveNode.RightTrianglePlaneY,
                                    -RemoveNode.RightTrianglePlaneZ,
                                ),
                            );
                        }

                        if (
                            faceName.equals(
                                new THREE.Vector3(
                                    -RemoveNode.ConcavePrismBackFaceNodeX,
                                    RemoveNode.ConcavePrismBackFaceNodeY,
                                    RemoveNode.ConcavePrismBackFaceNodeZ,
                                ),
                            )
                        ) {
                            // concave prism back face
                            this.filterNodeOrHole.call(
                                this,
                                faceName,
                                new THREE.Vector3(
                                    -RemoveNode.ConcavePrismBackFacePlaneX,
                                    RemoveNode.ConcavePrismBackFacePlaneY,
                                    RemoveNode.ConcavePrismBackFacePlaneZ,
                                ),
                            );
                        }

                        if (
                            faceName.equals(
                                new THREE.Vector3(
                                    RemoveNode.ConcavePrismBottomFaceNodeX,
                                    -RemoveNode.ConcavePrismBottomFaceNodeY,
                                    RemoveNode.ConcavePrismBottomFaceNodeZ,
                                ),
                            )
                        ) {
                            // concave prism bottom face
                            this.filterNodeOrHole.call(
                                this,
                                faceName,
                                new THREE.Vector3(
                                    RemoveNode.ConcavePrismBottomFacePlaneX,
                                    -RemoveNode.ConcavePrismBottomFacePlaneY,
                                    RemoveNode.ConcavePrismBottomFacePlaneZ,
                                ),
                            );
                        }

                        if (
                            faceName.equals(
                                new THREE.Vector3(RemoveNode.PrismNodeX, RemoveNode.PrismNodeY, RemoveNode.PrismNodeZ),
                            )
                        ) {
                            // prism top face
                            this.filterNodeOrHole.call(
                                this,
                                faceName,
                                new THREE.Vector3(
                                    RemoveNode.PrismPlaneX,
                                    RemoveNode.PrismPlaneY,
                                    -RemoveNode.PrismPlaneZ,
                                ),
                            );
                        }

                        if (
                            faceName.equals(
                                new THREE.Vector3(RemoveNode.PrismNodeX, -RemoveNode.PrismNodeY, RemoveNode.PrismNodeZ),
                            )
                        ) {
                            // prism bottom face
                            this.filterNodeOrHole.call(
                                this,
                                faceName,
                                new THREE.Vector3(
                                    RemoveNode.PrismPlaneX,
                                    -RemoveNode.PrismPlaneY,
                                    -RemoveNode.PrismPlaneZ,
                                ),
                            );
                        }

                        this.finalMesh.userData["nodeOrHole"] = this.finalMesh.userData["nodeOrHole"].filter(
                            (cylinderMesh: any) => !cylinderMesh.position.equals(faceName),
                        );

                        this.finalMesh.userData["nodeOrHole"].forEach((cylinderMesh: any) => {
                            if (cylinderMesh.choice === "node") {
                                meshWithPlane = CSG.union(meshWithPlane, cylinderMesh);
                            } else if (cylinderMesh.choice === "hole") {
                                meshWithPlane = CSG.subtract(meshWithPlane, cylinderMesh);
                            }
                        });
                        this.scene.children = this.scene.children.filter((child) => child.id === meshWithPlane.id);
                        meshWithPlane.userData["nodeOrHole"] = [];
                        meshWithPlane.userData["nodeOrHole"] = this.finalMesh.userData["nodeOrHole"];
                        this.finalMesh = meshWithPlane;
                        this.scene.add(this.finalMesh);
                        this.lightConfiguration();
                        this.shapeFaceLabel();
                    }
                } else {
                    this.snackBar.open("Face index not found", SnackBarConfig.Actions.CLOSE, {
                        duration: SnackBarConfig.Duration.MEDIUM,
                    });
                }
            } else {
                this.snackBar.open("Invalid intersection data", SnackBarConfig.Actions.CLOSE, {
                    duration: SnackBarConfig.Duration.MEDIUM,
                });
            }
        } else {
            this.snackBar.open("The selected object is not a BufferGeometry", SnackBarConfig.Actions.CLOSE, {
                duration: SnackBarConfig.Duration.MEDIUM,
            });
        }
    }

    public filterNodeOrHole(faceName: THREE.Vector3, targetPosition: THREE.Vector3): void {
        if (this.finalMesh.userData["nodeOrHole"]) {
            this.finalMesh.userData["nodeOrHole"] = this.finalMesh.userData["nodeOrHole"].filter(
                (cylinderMesh: THREE.Mesh) => !cylinderMesh.position.equals(targetPosition),
            );
        }
    }

    private getFaceNameFromNormal(normal: THREE.Vector3): number {
        const tolerance = 0.1;
        const up = new THREE.Vector3(0, 1, 0);

        if (Math.abs(normal.dot(new THREE.Vector3(0, 0, 1)) - 1) < tolerance) {
            this.faceName.left = this.selectedChoice;
            return 0;
        }
        if (Math.abs(normal.dot(new THREE.Vector3(0, 0, -1)) - 1) < tolerance) {
            this.faceName.right = this.selectedChoice;
            return 1;
        }
        if (Math.abs(normal.dot(new THREE.Vector3(1, 0, 0)) - 1) < tolerance) {
            this.faceName.front = this.selectedChoice;
            return 2;
        }

        if (Math.abs(normal.dot(new THREE.Vector3(-1, 0, 0)) - 1) < tolerance) {
            this.faceName.back = this.selectedChoice;
            return 3;
        }

        if (Math.abs(normal.dot(new THREE.Vector3(0, 1, 0)) - 1) < tolerance) {
            this.faceName.top = this.selectedChoice;
            return 4;
        }
        if (Math.abs(normal.dot(up.clone().negate()) - 1) < tolerance) {
            this.faceName.bottom = this.selectedChoice;
            return 5;
        }
        return -1;
    }

    ngOnDestroy() {
        if (this.animationFrameId) {
            cancelAnimationFrame(this.animationFrameId);
        }
        this.renderer.dispose();
    }
}
